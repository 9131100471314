import React from 'react';

import paperBottom from "../shared/images/PaperBackground_Bottom.png";
import aboutHeader from "../shared/images/about/About_MenuHeader.png";
import jhPic from "../shared/images/about/JH_Photo.png";
import history from "../shared/images/about/History_image_placeholder.png";


const AboutPage = () => {
    return (
    
        <main className="pl-3 pr-3 ">
<div className="container">

  <div className="row mb-5">
  
    {/* <!-- // Small to Large Dropdown Menu --> */}
  
    <div className="dropdown col-12 d-lg-none text-center center mt-3 ">
      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <h2 className="banner p-3" style={{borderRadius: "7px", color: "#ad9175", width: "240px"}}>About</h2>
      </button>
      <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
        <a className="dropdown-item" href="http://www.johnhowiesteak.com/" target="_blank" rel="noreferrer">
          JOHN HOWIE STEAK
        </a>
        <a className="dropdown-item" href="http://www.seastarrestaurant.com/" target="_blank" rel="noreferrer">
          SEASTAR RESTAURANT & RAW BAR
        </a>
        <a className="dropdown-item" href="http://www.sportrestaurant.com/" target="_blank" rel="noreferrer">
          SPORT RESTAURANT & BAR
        </a>
        <a className="dropdown-item" href="http://www.adriaticgrill.com/" target="_blank" rel="noreferrer">
          ADRIATIC GRILL
        </a>
      </div>
    </div>

    {/* <!-- // End Small Menu --> */}


    {/* <!-- Large Side Menu and Main --> */}

   
    <div id="sidebar" className="col-3 d-none d-lg-block center mt-5" style={{height: "100px", fontSize: "14px"}}>
      <div style={{textAlign: "center", width: "100%"}} >
        <div>
          <img src={aboutHeader} alt="PageTitle" />
          <div style={{textAlign: "center", maxWidth: "225px", margin: "0 auto" }} >
            <div style={{margin: "10px 0px 10px 10px"}} className="dotted_line"></div>
            <div>
              <a href="http://www.johnhowiesteak.com/" target="_blank" rel="noreferrer">
                <span className="leftMenuTitle">JOHN HOWIE STEAK</span>
              </a>
            </div>
            <div style={{margin: "10px 0px 10px 10px"}} className="dotted_line"></div>
            <div>
              <a href="http://www.seastarrestaurant.com" target="_blank" rel="noreferrer">
                <span className="leftMenuTitle">SEASTAR RESTAURANT &amp; RAW BAR</span>
              </a>
            </div>
            <div style={{margin: "10px 0px 10px 10px"}} className="dotted_line"></div>
            <div>
              <a href="http://www.sportrestaurant.com" target="_blank" rel="noreferrer">
                <span className="leftMenuTitle">SPORT RESTAURANT &amp; BAR</span>
              </a>
            </div>
            <div style={{margin: "10px 0px 10px 10px"}} className="dotted_line"></div>
            <div>
              <a href="http://www.adriaticgrill.com/" target="_blank" rel="noreferrer">
                <span className="leftMenuTitle">ADRIATIC GRILL</span>
              </a>
            </div>
            <div style={{margin: "10px 0px 10px 10px"}} className="dotted_line"></div>
          </div>
        </div>
      </div>
    </div>












    
      
   







    {/* <!-- Small to Medium Main Article and Pic --> */}
    <section className="col-sm-12  d-md-none ">
        <div className=" row  pl-3 pr-3 justify-content-center">

          <figure className="  mt-4" >
            <img src={jhPic} className="img-fluid" alt="" />
          </figure>
  
          <div className=" " style={{width: "750px ",textAlign: "left"}}>
            <h2 className="boarderBottom content_title">ABOUT JOHN HOWIE</h2>
            <p className="">
              Chef John Howie is widely recognized as the foremost expert
              on plank cooking, the time-honored Northwest Native American
              method of roasting fish, meats and fowl on cedar planks. He
              is the author of “The Cedar Plank Cookbook” and “The Plank
              Cookbook”. Chef Howie has five Award Winning Restaurants in
              the Puget Sound region. Seastar Restaurant & Raw Bar in
              Bellevue, and in Seattle. SPORT Restaurant & Bar, Adriatic
              Grill, Italian Cuisine & Wine Bar, and his name sake John
              Howie Steak. He has recently authored “Passion & Palate” a
              beautiful cookbook filled with stories, recipes, and
              beautiful photography, including over 240 recipes. He has
              appeared on Martha Stewart Living Television, CBS Early
              Morning Show, Food Network’s “Best Of,” the Food Channel’s
              United Tastes of America with Chef Jeffrey Saad, and Rick
              Browne’s Barbeque America. He has recently authored “Passion
              & Palate” a beautiful cookbook filled with stories, recipes,
              and beautiful photography, including over 240 recipes.
            </p>
            <p className="mt-2">
              
              He has recently authored “Passion & Palate” a beautiful
              cookbook filled with stories, recipes, and beautiful
              photography, including over 240 recipes. He has appeared on
              Martha Stewart Living Television, CBS Early Morning Show,
              Food Network’s “Best Of,” the Food Channel’s United Tastes
              of America with Chef Jeffrey Saad, and Rick Browne’s
              Barbeque America.
            </p>
            <h2 className="boarderBottom content_title">ABOUT PLANK COOKING</h2>
            <p className="mt-2">
              Cedar and Alder plank cooking was created by Northwest
              Native Americans. They would tie fish and game to large
              Western red cedar or alder planks. They would then place the
              planks around open fires, and the residual heat from the
              fire would cook the fish or game.
            </p>
  
            <p className="mt-2">
              
              This cooking procedure was done for hundreds of years, more
              out of a necessity for a cooking surface than anything else.
            </p>
  
            <p className="mt-2">
              
              In the late 1990’s I started experimenting with cooking
              planks. While developing the menu for Palisade Restaurant I
              was introduced to baking planks. I was intrigued by this
              method of cooking as it gave us a tie to the Northwest
              Native Americans and was a simple yet flavorful and healthy
              way to cook.
            </p>
  
            <p className="mt-2">
              
              Salmon was the first recipe I created for plank cooking.
              Chef Garrett Cho and I worked on perfecting a great salmon
              recipe which we eventually placed on the opening menu for
              Palisade-it quickly became one of the bestselling entrées.
            </p>
            <div className="row">
              <p className="col-12 col-sm-6">
                Soon after that I asked my step-father, Phil Bagwill if
                he’d make cedar planks for me to use. He did, and in so
                doing started a business for himself selling baking and
                BBQ planks to specialty and food stores. Phil came to me
                and asked me to create a cookbook for him. So my family
                and I ate from cedar and alder baking planks for months,
                until I had enough great recipes to make the Cedar Plank
                Cookbook-or the blue cookbook as we call it.
              </p>
              <img
              alt=""
                src={history}
                className="img-fluid center col-12 col-sm-6"
              />
            </div>
            <p className="mt-2">
              I also started experimenting with BBQ Planks, and within
              another year I had the Plankcooking Cookbook (or the green
              cookbook). It includes recipes specifically designed for
              Alder Baking Planks, Cedar BBQ Planks, as well as Cedar
              Baking Planks.
            </p>
            <h2 className="boarderBottom content_title">PLANK INFORMATION</h2>
            <p className="mt-2">
              Baking Planks – The benefits of using baking planks are
              many. They impart subtle wood flavors, and they maintain the
              natural juices in the meats and vegetables that are cooked
              on the plank, making for moist flavorful foods. They are
              easy to use and because they maintain the natural moisture
              you don’t need to add other fats, such as butter; but of
              course you still can if you want. Our baking planks come in
              two different woods-both Western red cedar and alder. The
              cedar is a little more aromatic and adds a stronger woodsy
              flavor to the foods that are cooked on it. The alder is
              milder and sweeter, with a very subtle flavor.
            </p>
            <p className="mt-2">
              The baking planks are designed to be used in your home oven.
              They are made to be used repeatedly for years to come. Using
              1 ½” thick kiln-dried wood, the center of the plank is
              routed out to form a cooking surface. Then we drill two
              holes through the plank on each end, and place a metal bolt
              running through the holes with a knot on each end. This is
              done to help keep the plank from cracking; or if it does
              crack to help hold it together so it can be used for years.
            </p>
            <p className="mt-2">
              BBQ Planks – Have similar benefits to that of the baking
              plank, in addition the BBQ planks add incredible flavor to
              the foods cooked on them-from the woodsy flavored cedar oils
              to the rich smoke from the charred edges and bottom. They,
              too, maintain natural moisture in the foods cooked on them.
              Our BBQ planks are made from clear Western red cedar. BBQ
              planks are designed to be used on your BBQ and are intended
              to be used one time.
            </p>
            <h2 className="boarderBottom content_title">
              BAKING PLANK – USE, CARE, HANDLING & CLEANING
            </h2>
            <p className="mt-2">
              Before using your plank for the first time it is best to oil
              the entire plank with an inexpensive vegetable oil. Using a
              paper towel, rub oil over the entire plank front, back,
              sides, and the cooking surface. Remove any excess oil with a
              clean paper towel. You only need to oil the plank once. The
              oil will help maintain the beauty of the natural woods and
              keep it from staining. After oiling it is ready for use.
            </p>
            <p className="mt-2">
              Baking planks never need to be soaked in water. They are to
              be used in your home oven. The oven should always be
              pre-heated to between 375° to 425º depending on the recipe.
              You will always use the oven in baking mode, never on the
              broiler. The plank should always be placed in the center of
              the oven on the metal rack. If your plank has a slight
              crack, it can be placed on a cookie sheet to ensure none of
              the juices flow onto the oven's surface. It is best to use a
              conventional oven. A convection oven can be used, but the
              whirling hot air can lead to a splitting or cracking of the
              plank.
            </p>
            <p className="mt-2">
              After use, baking planks should be washed with warm water
              and dish soap. I like to use the orange and yellow Tuffy™
              pad to remove any baked on juices or seasoning. Rinse with
              hot water and let the plank air dry. Place in a dry cupboard
              until next use.
            </p>
          </div>
        </div>
      </section>
{/* <!-- End Small to Medium Main --> */}
    
<section className="d-none d-md-block col-md-12 col-lg-9 mt-5 mb-5">
  <div className="row justify-content-center">
    <div id="" className=" "> 
        <figure className="jhBoardBackground" style={{width: "640px"}}>  
          <img src={jhPic} className="mainPic img-fluid" alt="" />
        </figure>
        </div>
        <div className="paperBackground " style={{marginTop: "-24px"}}>
          <div className="pr-4 pl-3" style={{width: "620px", textAlign: "left"}}>
            <h2 className="boarderBottom content_title">ABOUT JOHN HOWIE</h2>
            <p style={{fontSize: "14px"}}>
              Chef John Howie is widely recognized as the foremost
              expert on plank cooking, the time-honored Northwest Native
              American method of roasting fish, meats and fowl on cedar
              planks. He is the author of “The Cedar Plank Cookbook” and
              “The Plank Cookbook”. Chef Howie has five Award Winning
              Restaurants in the Puget Sound region. Seastar Restaurant
              & Raw Bar in Bellevue, and in Seattle. SPORT Restaurant &
              Bar, Adriatic Grill, Italian Cuisine & Wine Bar, and his
              name sake John Howie Steak. He has recently authored
              “Passion & Palate” a beautiful cookbook filled with
              stories, recipes, and beautiful photography, including
              over 240 recipes. He has appeared on Martha Stewart Living
              Television, CBS Early Morning Show, Food Network’s “Best
              Of,” the Food Channel’s United Tastes of America with Chef
              Jeffrey Saad, and Rick Browne’s Barbeque America. He has
              recently authored “Passion & Palate” a beautiful cookbook
              filled with stories, recipes, and beautiful photography,
              including over 240 recipes.
            </p>
            <p className="mt-2">
              
              He has recently authored “Passion & Palate” a beautiful
              cookbook filled with stories, recipes, and beautiful
              photography, including over 240 recipes. He has appeared
              on Martha Stewart Living Television, CBS Early Morning
              Show, Food Network’s “Best Of,” the Food Channel’s United
              Tastes of America with Chef Jeffrey Saad, and Rick
              Browne’s Barbeque America.
            </p>
            <h2 className="boarderBottom content_title">ABOUT PLANK COOKING</h2>
            <p className="mt-2">
              Cedar and Alder plank cooking was created by Northwest
              Native Americans. They would tie fish and game to large
              Western red cedar or alder planks. They would then place
              the planks around open fires, and the residual heat from
              the fire would cook the fish or game.
            </p>

            <p className="mt-2">
              
              This cooking procedure was done for hundreds of years,
              more out of a necessity for a cooking surface than
              anything else.
            </p>

            <p className="mt-2">
              
              In the late 1990’s I started experimenting with cooking
              planks. While developing the menu for Palisade Restaurant
              I was introduced to baking planks. I was intrigued by this
              method of cooking as it gave us a tie to the Northwest
              Native Americans and was a simple yet flavorful and
              healthy way to cook.
            </p>

            <p className="mt-2">
              
              Salmon was the first recipe I created for plank cooking.
              Chef Garrett Cho and I worked on perfecting a great salmon
              recipe which we eventually placed on the opening menu for
              Palisade-it quickly became one of the bestselling entrées.
            </p>
            <div className="row">
              <p className="col-5 col-sm-6">
                Soon after that I asked my step-father, Phil Bagwill if
                he’d make cedar planks for me to use. He did, and in so
                doing started a business for himself selling baking and
                BBQ planks to specialty and food stores. Phil came to me
                and asked me to create a cookbook for him. So my family
                and I ate from cedar and alder baking planks for months,
                until I had enough great recipes to make the Cedar Plank
                Cookbook-or the blue cookbook as we call it.
              </p>
              <figure className="col-6">
              <img
              alt=""
                src={history}
                className="img-fluid "
              />
            </figure>
            </div>
            <p className="mt-2">
              I also started experimenting with BBQ Planks, and within
              another year I had the Plankcooking Cookbook (or the green
              cookbook). It includes recipes specifically designed for
              Alder Baking Planks, Cedar BBQ Planks, as well as Cedar
              Baking Planks.
            </p>
            <h2 className="boarderBottom content_title">PLANK INFORMATION</h2>
            <p className="mt-2">
              Baking Planks – The benefits of using baking planks are
              many. They impart subtle wood flavors, and they maintain
              the natural juices in the meats and vegetables that are
              cooked on the plank, making for moist flavorful foods.
              They are easy to use and because they maintain the natural
              moisture you don’t need to add other fats, such as butter;
              but of course you still can if you want. Our baking planks
              come in two different woods-both Western red cedar and
              alder. The cedar is a little more aromatic and adds a
              stronger woodsy flavor to the foods that are cooked on it.
              The alder is milder and sweeter, with a very subtle
              flavor.
            </p>
            <p className="mt-2">
              The baking planks are designed to be used in your home
              oven. They are made to be used repeatedly for years to
              come. Using 1 ½” thick kiln-dried wood, the center of the
              plank is routed out to form a cooking surface. Then we
              drill two holes through the plank on each end, and place a
              metal bolt running through the holes with a knot on each
              end. This is done to help keep the plank from cracking; or
              if it does crack to help hold it together so it can be
              used for years.
            </p>
            <p className="mt-2">
              BBQ Planks – Have similar benefits to that of the baking
              plank, in addition the BBQ planks add incredible flavor to
              the foods cooked on them-from the woodsy flavored cedar
              oils to the rich smoke from the charred edges and bottom.
              They, too, maintain natural moisture in the foods cooked
              on them. Our BBQ planks are made from clear Western red
              cedar. BBQ planks are designed to be used on your BBQ and
              are intended to be used one time.
            </p>
            <h2 className="boarderBottom content_title">
              BAKING PLANK – USE, CARE, HANDLING & CLEANING
            </h2>
            <p className="mt-2">
              Before using your plank for the first time it is best to
              oil the entire plank with an inexpensive vegetable oil.
              Using a paper towel, rub oil over the entire plank front,
              back, sides, and the cooking surface. Remove any excess
              oil with a clean paper towel. You only need to oil the
              plank once. The oil will help maintain the beauty of the
              natural woods and keep it from staining. After oiling it
              is ready for use.
            </p>
            <p className="mt-2">
              Baking planks never need to be soaked in water. They are
              to be used in your home oven. The oven should always be
              pre-heated to between 375° to 425º depending on the
              recipe. You will always use the oven in baking mode, never
              on the broiler. The plank should always be placed in the
              center of the oven on the metal rack. If your plank has a
              slight crack, it can be placed on a cookie sheet to ensure
              none of the juices flow onto the oven's surface. It is
              best to use a conventional oven. A convection oven can be
              used, but the whirling hot air can lead to a splitting or
              cracking of the plank.
            </p>
            <p className="mt-2">
              After use, baking planks should be washed with warm water
              and dish soap. I like to use the orange and yellow Tuffy™
              pad to remove any baked on juices or seasoning. Rinse with
              hot water and let the plank air dry. Place in a dry
              cupboard until next use.
            </p>
          </div>
        </div>

        <div className="d-none d-lg-block ">
          <div className=" row">
              <figure className="ml-3" style={{width: "640px"}}>
                  <img src={paperBottom}  alt=""/>
              </figure>
          </div>
        </div>
        <div className="d-none d-md-block d-lg-none ">
          <div className=" row">
              <figure className="">
                  <img src={paperBottom} className="ml-3" alt=""/>
              </figure>
          </div>
        </div>
    
 </div>
 </section>
 </div>
</div>
</main>

    );
}

export default AboutPage;