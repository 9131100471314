import React from "react";

import CartItemsAdded from "../cart/CartItemsAdded";

// EVERY PAGE

export default function ShopSection(props) {
  return (
    <main className="pl-3 pr-3 ">
      <div className="container">
        <div className="row">
          {/* Small screen */}

          <section className="col-12  d-md-none ">
            <div className=" row mb- 5justify-content-center">
              <div className="col-12 mb-5">
                <div className="col-12" style={{ fontSize: "14px" }}>
                  <p>{props.categoryDescription}</p>
                  {props.productImagePath === "" ? (
                    <>
                      <div className="" style={{ fontSize: "14px" }}>
                        <p>{props.description}</p>
                      </div>
                      <div className="row">
                        <div className="col-8 ">
                          <p>{props.productName}</p>
                        </div>

                        <div className="col-4  ">
                          <p>{props.priceDescription} </p>

                          <input
                            className="mb-5"
                            style={{ width: "30px" }}
                            type="hidden"
                            name="productIdInput"
                            id="productIdInput"
                            value={props.productId}
                          />
                          <CartItemsAdded
                            className="mb-5"
                            name={props.productName}
                            productId={props.productId}
                            price={props.price}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="content_title boarderTop">
                        <div
                          className="dotted_line_bottom dotted_line_right col-7"
                          style={{ textAlign: "left" }}
                        >
                          {props.productName}
                        </div>
                      </div>
                      <div className="" style={{ fontSize: "14px" }}>
                        <p>{props.description}</p>
                      </div>
                      <div className="row">
                        <div className="col-8 " style={{ textAlign: "left" }}>
                          <p>{props.productName}</p>
                          <p>
                            <img src={props.productImagePath} alt="" />
                          </p>
                        </div>

                        <div className="col-4  ">
                          <p>{props.priceDescription}</p>

                          <input
                            style={{ width: "30px" }}
                            type="hidden"
                            name="productIdInput"
                            id="productIdInput"
                            value={props.productId}
                          />
                          <CartItemsAdded
                            name={props.productName}
                            productId={props.productId}
                            price={props.price}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* End Small screen  */}

          {/* Big Screen  */}
          <div className="col-3 d-none d-lg-block mb-5"></div>
          <section className="col-md-12 col-lg-9 d-none d-md-block">
            <div className=" row justify-content-center">
              <div className="paperBackground">
                <div className="" style={{ width: "610px", fontSize: "14px" }}>
                  <p>{props.categoryDescription}</p>

                  {props.productImagePath === "" ? (
                    <>
                      <div
                        className="mt-3"
                        style={{ textAlign: "left", fontSize: "14px" }}
                      >
                        <p>{props.description}</p>
                      </div>
                      <div className="row">
                        <div className="col-8 ">
                          <p>{props.productName}</p>
                        </div>

                        <div className="col-4 mb-5 ">
                          <p>{props.priceDescription} </p>

                          <input
                            className="mb-5"
                            style={{ width: "30px" }}
                            type="hidden"
                            name="productIdInput"
                            id="productIdInput"
                            value={props.productId}
                          />
                          <CartItemsAdded
                            className="mb-5"
                            name={props.productName}
                            productId={props.productId}
                            price={props.price}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="content_title boarderTop">
                        <div
                          className="dotted_line_bottom dotted_line_right col-7"
                          style={{ textAlign: "left" }}
                        >
                          {props.productName}
                        </div>
                      </div>
                      <div className="" style={{ fontSize: "14px" }}>
                        <p>{props.description}</p>
                      </div>
                      <div className="row">
                        <div className="col-8 " style={{ textAlign: "left" }}>
                          <p>{props.productName}</p>
                          <p>
                            <img src={props.productImagePath} alt="" />
                          </p>
                        </div>

                        <div className="col-4 ">
                          <p>{props.priceDescription}</p>

                          <input
                            className="mb-5"
                            style={{ width: "30px" }}
                            type="hidden"
                            name="productIdInput"
                            id="productIdInput"
                            value={props.productId}
                          />
                          <CartItemsAdded
                            className="mb-5"
                            name={props.productName}
                            productId={props.productId}
                            price={props.price}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
