import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import checkout from "../../components/shared/images/cart/Checkout_button.png";
import back from "../shared/images/cart/Back_button.png";
import ShippingToYou from "./ShippingToYou";
import { useDispatch } from "react-redux";
import { checkoutCart } from "./../../redux/actions/cartActions";
import { Store } from "./../../redux/Store";
import { useHistory } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

const CartPageTwo = () => {
  let history = useHistory();

  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameBInput, setFirstNameBInput] = useState("");
  const [lastNameBInput, setLastNameBInput] = useState("");
  const [addressBInput, setAddressBInput] = useState("");
  const [addressB2Input, setAddressB2Input] = useState("");
  const [cityBInput, setCityBInput] = useState("");
  const [stateBInput, setStateBInput] = useState("");
  const [zipBInput, setZipBInput] = useState("");
  const [phoneBInput, setPhoneBIput] = useState("");
  const [emailBInput, setEmailBInput] = useState("");
  const [comments, setComments] = useState("");

  // const [firstNameShippingError, setFirstNameShippingError] = useState("");
  // const [firstNameBillingError, setFirstNameBillingError] = useState("");
  // const [lastNameShippingError, setLastNameShippingError] = useState("");
  // const [lastNameBillingError, setLastNameBillingError] = useState("");
  // const [addressOneShippingError, setAddressOneShippingError] = useState("");
  // const [addressOneBillingError, setAddressOneBilling] = useState("");
  // const [cityShippingError, setCityShippingError] = useState("");
  // const [cityBillingError, setCityBillingError] = useState("");
  // const [stateShippingError, setStateShippingError] = useState("");
  // const [stateBillingError, setStateBillingError] = useState("");

  // const [zipShippingError, setZipShippingError] = useState("");
  // const [zipBillingError, setZipBillingError] = useState("");
  // const [stateBillingError, setStateBillingError] = useState("");

  useEffect(() => {
    const store = Store.getState();
    const customerDetails = store.cart.customerDetails;
    if (customerDetails !== undefined) {
      setFirstName(customerDetails.firstName);
      setLastName(customerDetails.lastName);
      setAddress(customerDetails.address);
      setAddress2(customerDetails.address2);
      setCity(customerDetails.city);
      setState(customerDetails.state);
      setZip(customerDetails.zip);
      setPhone(customerDetails.phone);
      setEmail(customerDetails.email);
      setComments(customerDetails.notes);
      setFirstNameBInput(customerDetails.firstNameBInput);
      setLastNameBInput(customerDetails.lastNameBInput);
      setAddressB2Input(customerDetails.addressB2Input);
      setAddressBInput(customerDetails.addressBInput);
      setCityBInput(customerDetails.cityBInput);
      setStateBInput(customerDetails.stateBInput);
      setZipBInput(customerDetails.zipBInput);
      setPhoneBIput(customerDetails.phoneBInput);
      setEmailBInput(customerDetails.emailBInput);
    }
  }, []);

  const fillBilling = () => {
    setFirstNameBInput(firstName);
    setLastNameBInput(lastName);
    setAddressBInput(address);
    setAddressB2Input(address2);
    setCityBInput(city);
    setStateBInput(state);
    setZipBInput(zip);
    setPhoneBIput(phone);
    setEmailBInput(email);
  };

  const Checkout = () => {
    const store = Store.getState();

    dispatch(
      checkoutCart({
        firstName: firstName,
        lastName: lastName,
        address: address,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        email: email,
        firstNameBInput: firstNameBInput,
        lastNameBInput: lastNameBInput,
        addressBInput: addressBInput,
        addressB2Input: addressB2Input,
        cityBInput: cityBInput,
        stateBInput: stateBInput,
        zipBInput: zipBInput,
        phoneBInput: phoneBInput,
        emailBInput: emailBInput,
        notes: comments,
        authorizationCode: uuidv4(),
        uniqueIdentifier: uuidv4(),
        websiteId: 1,
        transactionId: uuidv4(),
        orderCartId: store.cart.orderCartId,
        status: 2,
        orderTotal: store.cart.cartList.reduce(function (cnt, o) {
          return cnt + o.Price * o.Qty;
        }, 0),
      })
    );
    setTimeout(function () {
      history.push("/cart/checkout/update");
    }, 3000);
  };

  return (
    <>
      <main className=" mt-5 mb-5">
        <div className="container ">
          <div
            className="row cartBackground ml-5 cartFont justify-content-center"
            style={{ textAlign: "left" }}
          >
            <p>Enter Billing & Shipping Information Step 2 of 4</p>
          </div>
          <div className="row lightCartBackground ml-5  cartFont ">
            {/* <!-- Left side --> */}
            <div
              className="col-12 col-md-6 pl-1  "
              style={{
                borderLeft: "1px dashed #929292",
                borderBottom: "1px dashed #929292",
                borderTop: "1px dashed #929292",
                borderRight: "1px dashed #929292",
              }}
            >
              <p
                className="cartBackground p-1"
                style={{
                  borderBottom: "1px dashed #929292",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                Billing Address
              </p>

              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  First Name:
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className=" form-control form-control-sm"
                    required
                    name="firstNameInput"
                    id="firstNameInput"
                  />
                  <span style={{ color: "red" }}>
                    {" "}
                    {/* {firstNameShippingError} */}
                  </span>
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Last Name:
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={lastName}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    className=" form-control form-control-sm"
                    name="lastNameInput"
                    id="lastNameInput"
                  />
                  {/* <span style={{ color: "red" }}> {lastNameShippingError}</span> */}
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Address:
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={address}
                    required
                    onChange={(e) => setAddress(e.target.value)}
                    className=" form-control form-control-sm"
                    name="addressInput"
                    id="addressInput"
                  />
                  <span style={{ color: "red" }}>
                    {" "}
                    {/* {addressOneShippingError} */}
                  </span>
                </div>
              </div>
              <div
                className="form-group row mt-2 "
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className="col-form-label col-form-label-sm ml-3"
                >
                  Address 2:
                </label>
                <div className="col-sm-7 ml-4">
                  <input
                    type="text"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    className=" form-control form-control-sm"
                    placeholder="Optional"
                    name="addressTwoInput"
                    id="addressTwoInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  City:
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    className=" form-control form-control-sm"
                    name="cityInput"
                    id="cityInput"
                  />

                  {/* <span style={{ color: "red" }}> {cityShippingError}</span> */}
                </div>
              </div>

              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  style={{ paddingRight: "-15px", paddingLeft: "-15px" }}
                  htmlFor="stateInput"
                  className="col-2 col-form-label col-form-label-sm col-3"
                >
                  State:
                </label>
                <div className="col-sm-7">
                  <select
                    value={state}
                    required
                    onChange={(e) => setState(e.target.value)}
                    style={{
                      width: "225px",
                      margin: "5px",
                      paddingRight: "-15px",
                      paddingLeft: "-15px",
                    }}
                    id="stateInput"
                    name="stateInput"
                    className=""
                  >
                    <option value="?">Select</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">Dist of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  {/* <span style={{ color: "red" }}> {stateShippingError}</span> */}
                </div>
              </div>

              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Zip / Postal:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    value={zip}
                    required
                    type="text"
                    onChange={(e) => setZip(e.target.value)}
                    className=" form-control form-control-sm"
                    name="zipInput"
                    id="zipInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor=""
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Phone:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    value={phone}
                    required
                    type="number"
                    onChange={(e) => setPhone(e.target.value)}
                    className=" form-control form-control-sm"
                    name="phoneInput"
                    id="phoneInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor=""
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Email:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className=" form-control form-control-sm"
                    name="emailInput"
                    id="emailInput"
                  />
                </div>
              </div>
            </div>

            {/* <!-- Right Side --> */}
            <div
              className="col-12 col-md-6 pl-1  "
              style={{
                borderBottom: "1px dashed #929292",
                borderTop: "1px dashed #929292",
                borderRight: "1px dashed #929292",
              }}
            >
              <div className="cartBackground p-1" style={{ textAlign: "left" }}>
                Shipping Address {"  "}
                <input
                  className="ml-3"
                  type="checkbox"
                  id="billingCheck"
                  name="billingCheck"
                  onChange={(e) => {
                    if (e.target.checked) {
                      fillBilling();
                    }
                  }}
                  value="true"
                />
                Same as Billing Address
              </div>

              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  First Name:
                </label>
                <div className="col-sm-7">
                  <input
                    value={firstNameBInput}
                    required
                    type="text"
                    onChange={(e) => setFirstNameBInput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="firstNameBInput"
                    id="firstNameBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Last Name:
                </label>
                <div className="col-sm-7">
                  <input
                    value={lastNameBInput}
                    required
                    type="text"
                    onChange={(e) => setLastNameBInput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="lastNameBInput"
                    id="lastNameBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Address:
                </label>
                <div className="col-sm-7">
                  <input
                    value={addressBInput}
                    required
                    type="text"
                    onChange={(e) => setAddressBInput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="addressBInput"
                    id="addressBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2 "
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className="col-form-label col-form-label-sm ml-3"
                >
                  Address 2:
                </label>
                <div className="col-sm-7 ml-4">
                  <input
                    value={addressB2Input}
                    type="text"
                    onChange={(e) => setAddressB2Input(e.target.value)}
                    className=" form-control form-control-sm"
                    placeholder="Optional"
                    name="addressBTwoInput"
                    id="addressTwoBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  City:
                </label>
                <div className="col-sm-7">
                  <input
                    value={cityBInput}
                    required
                    type="text"
                    onChange={(e) => setCityBInput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="cityBInput"
                    id="cityBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  State:
                </label>
                <div className="col-sm-7">
                  <select
                    required
                    value={stateBInput}
                    onChange={(e) => setStateBInput(e.target.value)}
                    style={{
                      width: "225px",
                      margin: "5px",
                      paddingRight: "-15px",
                      paddingLeft: "-15px",
                    }}
                    id="stateInput"
                    name="stateInput"
                    className=""
                  >
                    <option value="?">Select</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">Dist of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Zip / Postal:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    required
                    type="text"
                    value={zipBInput}
                    onChange={(e) => setZipBInput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="zipBInput"
                    id="zipBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Phone:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    required
                    type="number"
                    value={phoneBInput}
                    onChange={(e) => setPhoneBIput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="phoneBInput"
                    id="phoneBInput"
                  />
                </div>
              </div>
              <div
                className="form-group row mt-2"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor="colFormLabelSm"
                  className=" col-form-label col-form-label-sm col-3"
                >
                  Email:{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    required
                    type="email"
                    value={emailBInput}
                    onChange={(e) => setEmailBInput(e.target.value)}
                    className=" form-control form-control-sm"
                    name="emailBInput"
                    id="emailBInput"
                  />
                </div>
              </div>
            </div>
            <ShippingToYou />
          </div>

          {/* <!-- Comments --> */}
          <div className="row  lightCartBackground ml-5 cartFont p-2">
            <div
              className="col-12  pl-2 pb-4 justify-content-center"
              style={{
                width: "500px",
                height: "25px",
                textAlign: "left",
                padding: "5px",
                backgroundColor: "#d2be9d",
                color: "#4b290c",
                fontWeight: "bold",
                border: "1px dashed #929292",
              }}
            >
              Comments or Additional Information
            </div>
            <div
              className="d-flex col-12 pb-2"
              style={{ border: "1px dashed #929292", left: "0px" }}
            >
              <textarea
                value={comments}
                name="commentsInput"
                id="commentsInput"
                className="p-1 mt-1 "
                onChange={(e) => setComments(e.target.value)}
                rows="4"
                cols="35"
              ></textarea>
            </div>
          </div>

          {/* <!-- Buttons --> */}
          <div className="d-flex row justify-content-end lightCartBackground formBorder ml-5">
            <div className="p-1">
              <NavLink to="/cart" className="pr-1">
                <figure className="">
                  <img src={back} alt="" style={{ borderRadius: "3px" }} />
                </figure>
              </NavLink>
            </div>
            <div className="p-1">
              <figure className="">
                <img
                  type="button"
                  alt="Add to cart"
                  style={{ marginTop: "0px" }}
                  src={checkout}
                  onClick={() => {
                    // console.log(firstName)
                    // console.log(lastName)
                    // console.log(address)
                    // console.log(city)
                    // console.log(state)
                    // // console.log(city)
                    //     //Add Validations here before going to API call.
                    //     let shippingName = false;
                    //     let shippinglast = false;
                    //     let shippingAddress = false;
                    //     let shippingCity =false;
                    //     let shippingState = false;
                    //     let shippingZip = false;

                    //   let errorCtr = 0
                    //     // if (firstName === ""  || firstName === null || firstName === undefined ) {
                    //       if (firstName === 0  || firstName === null || firstName === undefined  ) {
                    //         setFirstNameShippingError("You must enter a name")
                    //         shippingName = false;
                    //         errorCtr += 1
                    //     }

                    //     else if(lastName === ""  || lastName === null || lastName === undefined ){
                    //       setLastNameShippingError("You must enter a name with more than 3 characters")
                    //       shippinglast = false;
                    //         errorCtr += 1
                    //     }

                    //     else if(address === ""  || address === null || address === undefined){
                    //       setAddressOneShippingError("You must enter a address with more than 5 characters")
                    //       shippingAddress = false;
                    //         errorCtr += 1
                    //     }

                    //     else if(city === ""  || city === null || city === undefined || city < 3){
                    //       setCityShippingError("You must enter a city with more than 3 characters")
                    //       shippingCity = false;
                    //         errorCtr += 1
                    //     }

                    //     else if(state === ""  || state === null || state === undefined || state < 3){
                    //       setStateShippingError("You must enter a state with 2 or more characters")
                    //       shippingState = false;
                    //         errorCtr += 1
                    //     }

                    //     else if(shippingZip === ""  || shippingZip === null || shippingZip === undefined || shippingState < 5){
                    //       setZipShippingError("You must enter a city with more than 3 characters")
                    //       shippingZip = false;
                    //         errorCtr += 1
                    //     }

                    //     else {
                    //       shippingName = true;
                    //       shippingLast = true;
                    //       shippingAddress = true;
                    //       shippingCity = true;
                    //       shippingState = true;
                    //       shippingZip = true;
                    //       errorCtr = 0;

                    //       setFirstNameShippingError("")
                    //       setLastNameShippingError("")
                    //       setAddressOneBilling("")
                    //       setCityShippingError("")
                    //       setStateShippingError("")

                    //     }

                    // IF checkbox checked set billing check to true other wise false

                    // if(billingCheck === false){
                    //   let billingName = false;
                    //   let billinglast = false;
                    //   let billingAddress = false;
                    //   let billingCity = false;
                    //   let billingState = false;
                    //   let billingZip  = false;

                    //   let errorCtr = 0
                    // // if (firstName === ""  || firstName === null || firstName === undefined ) {
                    //   if (billingName === 0  || billingName === null || billingName === undefined  ) {
                    //     setFirstNameBillingError("You must enter a name")
                    //     billingName = false;
                    //     errorCtr += 1
                    // }

                    // else if(billinglast === ""  || billinglast === null || billinglast === undefined ){
                    //   setLastNameBillingError("You must enter a name with more than 3 characters")
                    //   billinglast = false;
                    //     errorCtr += 1
                    // }

                    // else if(billingAddress === ""  || billingAddress === null || billingAddress === undefined){
                    //   addressOneBillingError("You must enter a address with more than 5 characters")
                    //   billingAddress = false; shippingAddress = false;
                    //     errorCtr += 1
                    // }

                    // else if(billingCity === ""  || billingCity === null || billingCity === undefined || city < 3){
                    //   setCityBillingError("You must enter a city with more than 3 characters")
                    //   billingCity = false;
                    //     errorCtr += 1
                    // }

                    // else if(billingState === ""  || billingState === null || billingState === undefined || state < 3){
                    //   setStateBillingError("You must enter a state with 2 or more characters")
                    //   billingState = false;
                    //     errorCtr += 1
                    // }

                    // else if(shippingZip === ""  || shippingZip === null || shippingZip === undefined || shippingState < 5){
                    //   setZipBillingError("You must enter a city with more than 3 characters")
                    //   billingZip  = false;
                    //     errorCtr += 1
                    // }

                    // else {
                    //   let billingName = true;
                    //   let billinglast = true;
                    //   let billingAddress = true;
                    //   let billingCity = true;
                    //   let billingState = true;
                    //   let billingZip  = true;

                    //   setFirstNameBillingError("")
                    //   setLastNameBillingError("")
                    //   setCityBillingError("")
                    //   setStateBillingError("")
                    //   setZipBillingError("")
                    // }
                    //   console.log('Checkmark not checked and can validate errors')
                    // } else if(billingCheck === true) {
                    //   setFirstNameBillingError("")
                    //   setLastNameBillingError("")
                    //   setCityBillingError("")
                    //   setStateBillingError("")
                    //   setZipBillingError("")
                    //   console.log("Checkmark checked and don't need to validate")
                    // }

                    // if(errorCtr === 0 || errorCtr === ""){
                    Checkout();
                    // }
                  }}
                />
              </figure>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CartPageTwo;
