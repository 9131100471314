import { combineReducers } from "redux";
import cartReducer from "./cartReducers"
import categoriesSlice from "./../categoriesSlice";
import productsSlice from '../productsSlice';


const RootReducer = combineReducers({
  cart: cartReducer,
  categories: categoriesSlice,
  products: productsSlice
});

export default RootReducer;
