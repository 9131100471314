import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux"
import addToCartPic from '../shared/images/shop/AddToCart_button.png'
import { Store } from '../../redux/Store';
import { addProductToCart, updateCartQuantity } from "../../redux/actions/cartActions";

const CartItemsAdded = (props) => {

  const [errorMsg, setErrorMsg] = useState("")
  const [qty, setQty] = useState("");
  const [isItemAdded, setItemAdded] = useState(false);
  const dispatch = useDispatch()

  async function AddToCart(orderCartId) {
    dispatch(addProductToCart({
      Name: props.name,
      Qty: qty,
      Price: props.price,
      ProductID: props.productId,
      orderCartId: orderCartId,
    })
    )
  }

  async function UpdateToCart(orderCartId) {
    dispatch(updateCartQuantity({
      Name: props.name,
      Qty: qty,
      Price: props.price,
      ProductID: props.productId,
      orderCartId: orderCartId,
    })
    )
  }

  return (
    <>
      <p>

        <input
          className="text-center form-group"
          type="number"
          style={{ width: "30px" }}
          id="qtyInput"
          name="qtyInput"
          // pattern="(\d\[0-9])"
          maxLength="2"
          step='1'
          min='1'
          max='99'
          value={parseInt(Number(qty))}
          onChange={(e) => {
            if (Number(e.target.value) > 0) {
              setErrorMsg("");
            }
            setQty(e.target.value)
          }}
        />

        <br />


        <img type="button" alt="Add to cart" src={addToCartPic} onClick={() => {
          if (Number(qty) === 0) {
            setErrorMsg("Please select quantity.");
            return;
          }
          else {
            const store = Store.getState();
            console.log(store);
            const currentProduct = store.cart.cartList && store.cart.cartList.filter(function (item) {
              return item.ProductID === props.productId;
            });
            if (currentProduct && currentProduct.length > 0) {
              UpdateToCart(store.cart.orderCartId);
            }
            else {
              AddToCart(store.cart.orderCartId);
            }
            setItemAdded(true);
          }
        }
        }
        />
        </p>
        {isItemAdded ? <p>Added To Cart - <NavLink to='/cart' >View Cart</NavLink> </p> : ""}
        {errorMsg !== "" ? <p style={{ color: "red" }}>{errorMsg}</p> : ""}
      
    </>
  );
};

export default CartItemsAdded;