import React, {useState, useEffect} from 'react'

import {NavLink} from 'react-router-dom'
import {useDispatch} from 'react-redux';
import backButton  from '../shared/images/cart/Back_button.png'
import purchase  from '../shared/images/cart/Purchase_button.png'
import {Store} from './../../redux/Store';
import { useHistory } from "react-router-dom";
import {purchaseCart} from "./../../redux/actions/cartActions";

// import MaskInput from 'react-maskinput';

const CartPageThree = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [ccType, setSetccType] = useState("");
  const [shippingType, setShippingType] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [, setCardNumber ] = useState("");
  const [, setExpMonth] = useState("");
  const [, setExpYear] = useState("");
  const [subtotal, setSubTotal] = useState(0);
  const [carts,setCarts] = useState([]);
  const [customer, setCustomer] = useState({});

  // const [error, setErrors] = useState("");


//   const [errorMessage, setErrorMessage] = useState('') 
    
// const validateCreditCard = (value) => { 
  
//   if (validator.isCreditCard(value)) { 
//     setErrorMessage('Valid CreditCard Number') 
//   } else { 
//     setErrorMessage('Enter valid CreditCard Number!') 
//   } 
// } 



  useEffect(() => {
    const store = Store.getState();
      console.log(store.cart);
      setCarts(store.cart.cartList);
      setSubTotal(store.cart.cartList.reduce( function(cnt,o){ return cnt + (o.Price * o.Qty); }, 0));
      setCustomer(store.cart.customerDetails);
  }, [])

  function purchase_cart(){
    const store = Store.getState();
      dispatch(
        purchaseCart({
          shippingType: shippingType,
          shippingCost: shippingCost,
          ccType: ccType,
          orderCartId: store.cart.orderCartId,
        })
      )
      setTimeout(function() {history.push('/cart/checkout/purchase')}, 2000);
  }

  const renderedCarts = carts.map((cart) => (
  
    <tr className="scope">
      <td
        style={{
          width: "500px",
          textAlign: "left",
          padding: "5px",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className=""
      >
        {cart.Name}
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className="ng-binding"
      >
        ${cart.Price}
      </td>
      <td
        style={{
          width: "100px",
          padding: "5px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
      >
        {cart.Qty}
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
          borderRight: "1px dashed #929292",
        }}
      >
        <div style={{ paddingTop: "10px" }} className="ng-binding">
          ${cart.Qty * cart.Price}
        </div>
        <div
          style={{ paddingTop: "10px", paddingBottom: "5px", textAlign: "center", }}
        >
        </div>
      </td>
    </tr>
  ));
  
  return (
   
    <main className="pl-3 pr-3 mb-5">
    <div className="container mt-5">
    
    <div className="row  cartBackground ml-5 cartFont ">
      <div className="col-12 pt-2 pb-2" style={{fontSize:"16px"}}>
        <p><b>{'Preview Order & Enter Payment Information > Step 3 of 4'}</b></p>
      </div>
    </div>

    <div className="row  lightCartBackground ml-5 cartFont">
      <div className="col-6 np" style={{borderLeft: "1px dashed #929292" ,borderBottom: "1px dashed #929292", borderRight: "1px dashed #929292"}}>
        <div className="p-1 cartBackground">
        <p>Billing Address</p>
        </div>
      </div>
      <div className="col-6 np" style={{borderBottom: "1px dashed #929292", borderRight: "1px dashed #929292"}}>
      <div className=" p-1 cartBackground">
        <p>Shipping Address</p>
        </div>
      </div>


      <div className="col np">
        <table
          border="0"
          cellPadding="5"
          cellSpacing="5"
          width="100%"
          className="formBorder"
          style={{color: "#4b290c"}}
        >
          
          <tbody style={{borderLeft: "1px dashed #929292" ,borderBottom: "1px dashed #929292", borderRight: "1px dashed #929292"}}>
            <tr className="mb-5 lightCartBackground">
              <td className="moveRight formPadding">Name:</td>
              <td className="formPadding center">
                {customer.firstNameBInput} 
                {customer.lastNameBInput}
              </td>
            </tr>
            <tr className="lightCartBackground" >
              <td className="moveRight formPadding">Address:</td>
              <td className="formPadding center">
                <p className="pr-1">
                  {customer.addressBInput }
                  {" "}
                  {customer.cityBInput} 
                  {" "}
                  {customer.stateBInput} 
                  {" "}
                 {customer.zipBInput}  
                </p>
                              </td>
            </tr>

            <tr className="lightCartBackground">
              <td className="moveRight formPadding">Phone:</td>
              <td className="formPadding center">
                 {customer.phoneBInput }
              </td>
            </tr>
            <tr className="lightCartBackground">
              <td className="moveRight formPadding">Email:</td>
              <td className="formPadding center">
               {customer.emailBInput}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col np">
        <table
          border="0"
          cellPadding="5"
          cellSpacing="5"
          width="100%"
          className="formBorder"
          style={{color: "#4b290c"}}
        >

          <tbody style={{borderBottom: "1px dashed #929292", borderRight: "1px dashed #929292"}}>
            <tr className="mb-5 lightCartBackground">
              <td className=" formPadding">Name:</td>
              <td className="formPadding center">
               {customer.firstName} 
                 {customer.lastName } 
                </td> 
            </tr>
            <tr className="lightCartBackground " >
              <td className=" formPadding">Address:</td>
              <td className="formPadding center">
                <p className="stepThreeSpacer">
                {customer.address}  
                {" "}
               {customer.city} 
               {" "}
                {customer.state} 
                {" "}
                {customer.zip} 
                </p>
              </td>
            </tr>

            <tr className="lightCartBackground">
              <td className=" formPadding">Phone:</td>
              <td className="formPadding center">
                {customer.phone} 
              </td>
            </tr>
            <tr className="lightCartBackground">
              <td className=" formPadding">Email:</td>
              <td className="formPadding center">
                 {customer.email } 
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>

   <div className="row  lightCartBackground ml-5 cartFont">
       

<div style={{ backgroundColor: "#e8e3c8", margin: "15px 0 0 0", padding: "1px" }} className="container">
<table border="0" cellPadding="0" cellSpacing="0" width="100%">
  <tbody>
    <tr>
      <td
        style={{ width: "500px", height: "25px", textAlign: "left", padding: "5px", backgroundColor: "#d2be9d", color: "#4b290c", fontWeight: "bold", borderTop: "1px dashed #929292", borderLeft: "1px dashed #929292" }}>
        Shipping to You
</td>
      <td className="cartTableLabel" style={{ borderLeft: "1px dashed #929292" }}>
        Price
</td>
      <td className="cartTableLabel" style={{ borderLeft: "1px dashed #929292" }}>
        Quantity
</td>
      <td className="cartTableLabel" style={{ borderLeft: "1px dashed #929292" }}>
        Total
</td>
    </tr>
  
{renderedCarts}
    
    <tr>
      <td
        style={{ width: "500px", textAlign: "left", padding: "5px", color: "#4b290c", borderTop: "1px dashed #929292", borderLeft: "1px dashed #929292", fontWeight: "bold" }} >
       
      </td>
      <td
        style={{ width: "100px", paddingTop: "5px", height: "35px", color: "#4b290c", fontWeight: "bold", textAlign: "center", borderTop: "1px dashed #929292" }}>
        &nbsp; 
      </td>
      <td
        style={{ width: "100px", height: "35px", color: "#4b290c", fontWeight: "bold", textAlign: "right", borderTop: "1px dashed #929292",  }}>
        Subtotal:{" "} 
      </td>

      <td
        style={{ width: "100px", height: "35px", color: "#4b290c", fontWeight: "bold", textAlign: "center", borderTop: "1px dashed #929292", borderRight: "1px dashed #929292",  }}
        className="">
        ${subtotal}
      </td>
    </tr>
    <tr>
     
    </tr>
  </tbody>
</table>
</div>

       <div className="col-11 d-flex justify-content-end" style={{  borderLeft: "1px dashed #929292"}}> 
       <p className="pr-2">*Shipping & Handling:{" "}</p>
       <select required onChange={(e) => 
        {
          setShippingType(e.target.value);
          if(e.target.value === "1")
          {
            setShippingCost(10);
          }
          else if(e.target.value === "2"){
            setShippingCost(15);
          }
          else {
            setShippingCost(0);
          }
        }
        } className="form-group col-3">
         <option>Select</option>
         <option value={1}>USPS Parcel 3-8 Days</option>
         <option value={2}>USPS Priority 2-4 Days</option>
       </select>
       </div>
       
      <div className="col-1 pr-5" style={{borderRight: "1px dashed #929292"}}>{shippingType.value === 0 ? (<> $ 0 </>) : <>${shippingCost}</>} </div>
       
       <div className="col-9 p-2" style={{borderBottom: "1px dashed #929292",  borderLeft: "1px dashed #929292"}}></div>
       
       <div className="col-1 p-2 pl-5"  style={{borderBottom: "1px dashed #929292" , textAlign: "right "}}>Total</div>
       <div className="col-2 pl-5 "  style={{borderBottom: "1px dashed #929292"  ,borderRight: "1px dashed #929292"}}>${subtotal + shippingCost}</div>
       
       <p className="col-12 d-flex justify-content-end">
           *Shipping durations are estimates and not a guarantee
        </p>
   </div>

      <div className="row  lightCartBackground ml-5 cartFont">
        <div className="col-12 " style={{border: "1px dashed #929292", backgroundColor: "#D2BE9D"}}>
          <p>Extra Comments</p>
        </div>
        <div className="col-12" style={{borderLeft: "1px dashed #929292", borderBottom: "1px dashed #929292",  borderRight: "1px dashed #929292"}}>
          <p className="mt-1" >
             {customer.notes} 
          </p>
        </div>
      </div>  

    <div className="row lightCartBackground ml-5  mt-3 " style={{fontSize:"14px", color:"black"}}>
    <div className="col-12 pl-2 justify-content-center text-center"
       style={{width: "500px", fontSize: "16px", height: "25px", textAlign:"left", padding: "0", backgroundColor: "#d2be9d", color: "#4b290c", fontWeight: "bold", borderTop: "1px dashed #929292", borderLeft: "1px dashed #929292", borderRight: "1px dashed #929292"}}>
         Payment Information
       </div>

       <div className="col-12 d-flex " style={{  borderLeft: "1px dashed #929292", borderRight: "1px dashed #929292"}}> 
       <p className="pr-2">Card Type:{" "}</p>
       <select onChange={(e) => {
         setSetccType(e.target.value);
       }} required className="form-group col-2">
         <option>Select</option>
         <option value={'Visa'}>Visa</option>
         <option value={'MasterCard'}>MasterCard</option>
         <option value={'Discover'}>Discover</option>
         <option value={'American Express'}>American Express</option>
       </select>
       </div>

       <div className="col-12 d-flex " style={{  borderLeft: "1px dashed #929292", borderBottom: "1px dashed #929292" , borderRight: "1px dashed #929292"}}> 
       <p className=" row pr-3 pl-1">Card Number:{" "}</p> 
       {/* <MaskInput alwaysShowMask onChange={(e) => setCardNumber(e.target.value)} maskChar="_" mask="0000-0000-0000-0000" size={16} />; */}
         <input className="form-group col-2" onChange={(e) => setCardNumber(e.target.value)} 
         pattern="[^0-9]"
         type="tel"
         maxLength="12"
         required
         min='10'
         max='12'
        //  value={parseInt(Number(cardNumber))}
         name="ccNumberInput" id="ccNumberInput" />
       
        {/* <span>Enter CreditCard: </span><input type="text" 
        onChange={(e) => validateCreditCard(e.target.value)}/> <br /> 
        <span style={{ 
          fontWeight: 'bold', 
          color: 'red', 
        }}>{errorMessage}</span>  */}
     


       <p className=" row pr-3 pl-3">Experation:{" "}</p>
       <select className="form-group col-2" onChange={(e) => setExpMonth(e.target.value)} >
         <option>Select</option>
         <option>January</option>
         <option>Febuary</option>
         <option>March</option>
         <option>April</option>
         <option>May</option>
         <option>June</option>
         <option>July</option>
         <option>August</option>
         <option>September</option>
         <option>October</option>
         <option>November</option>
         <option>December</option>
       </select>
       <select className="form-group col-2" onChange={(e) => setExpYear(e.target.value)} >
         <option>Select</option>
         <option>2021</option>
         <option>2022</option>
         <option>2023</option>
         <option>2024</option>
         <option>2025</option>
       </select>
       
       </div>
       </div>
           
     
{/* Need to call the API cart/Purchase and save the details in store. */}

    <div className="row  lightCartBackground ml-5 cartFont">
      <div className="col-12  d-flex justify-content-end align-self-center" style={{border: "1px dashed #929292"}}>
        <NavLink  to="/cart/checkout">
          <figure className="pr-1 mt-3">
              <img src={backButton} alt="" style={{borderRadius: "3px"}}/>

          </figure> 
      </NavLink>  
          
            <figure className="mt-3">
              <img src={purchase} alt="" onClick={() => {


// if(errors === 0){
  purchase_cart()
// }


                ;}}/>
            </figure>     
      </div>
    </div>
  </div>
 
  </main>
  
  )
}

export default CartPageThree;
