import { createSlice } from '@reduxjs/toolkit';


import spicerubHeader from "../components/shared/images/shop/SpiceRubs_Header.png";
import cookBookHeader from "../components/shared/images/shop/Cookbooks_Header.png";
import BPHeader from "../components/shared/images/shop/BakingPlanks_Header.png";
import BBQHeader from "../components/shared/images/shop/BBQPlanks_Header.png";
import NutHeader from "../components/shared/images/shop/Cookbooks_Header.png";

const initialState = [
    {  
        categoryId: 1,
        name: "SpiceRubs", 
        sortOrder: 1,
        websitePath: "/shop/spicerubs",
        description: "Chef Howie created 3 Chefs In A Tub spice rubs and seasoning blends to bring professional flavor to home cooking. Very easy to use. Just season cook and serve. Made with all natural ingredients, dried herbs and mushrooms, spices, lemon, garlic, onion, brown sugar and kosher salt, no MSG or other chemical additives.",
        imagePath: spicerubHeader
    },
    {
        categoryId: 2,
        name: "Cookbooks", 
        sortOrder: 1,
        websitePath: "/shop/cookbooks",
        description: "",
        imagePath: cookBookHeader
    },
    {
         categoryId: 3,
         name: "Baking Planks", 
         sortOrder: 1,
         websitePath: "/shop/bakingplanks",
         description: "Chef Howie created 3 Chefs In A Tub spice rubs and seasoning blends to bring professional flavor to home cooking. Very easy to use. Just season cook and serve. Made with all natural ingredients, dried herbs and mushrooms, spices, lemon, garlic, onion, brown sugar and kosher salt, no MSG or other chemical additives.",
         imagePath: BPHeader
    },
    {
        categoryId: 4,
        name: "BBQ Planks", 
        sortOrder: 1,
        websitePath: "/shop/bbqplanks",
        description: "",
        imagePath: BBQHeader
    },
    {
       categoryId: 5,
       name: "Nutdriver", 
       sortOrder: 1,
       websitePath: "/shop/nutdriver",
       description: "",
       imagePath: NutHeader
    }
]

const categoriesSlice = createSlice ({
    name:"categories",
    initialState,
    reducer:{
        loadCategories(state, action){
            state.push(action.payload)
        }
    }
})

export const categories = state => state.categories.value;
export const {loadCategories} = categoriesSlice.actions;
export default categoriesSlice.reducer;
