import React from "react";
import {useSelector} from 'react-redux';

// import ShopSection from "./ShopSection";
import ProductSection from "./ProductSection";
import CategorySideMenu from "./CategorySideMenu";

import PaperBottom from "./PaperBottom";

const SpiceRubsPage = () => {
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);

  return (
    <>
      <div className="row  justify-content-center">
        {categories.map((category) => (
          <>
            {category.categoryId === 1 ? (
              <CategorySideMenu categoryImagePath={category.imagePath} />
            ) : (
              ""
            )}
          </>
        ))}
      </div>
      {products.map((product) => (
        <>
          {product.categoryId === 1 ? (
            <ProductSection

              netweight={product.ounces}
              productId={product.ProductID}
              productName={product.name}
              productOunces={product.ounces}
              productDescription={product.description}
              priceDescription={product.priceDescription}
              productImagePath={product.imagePath}
              productCategoryId={product.categoryId === 1}
              price={product.price}
            />
          ) : (
            ""
          )}
        </>
      ))}
      <PaperBottom />
    </>
  );
};

export default SpiceRubsPage;
