import React from 'react';

import seafood from '../shared/images/reviews/SimplySeafood_logo.png';
import johnMartha from '../shared/images/reviews/JHandMartha_photo.png';
import recipes from '../shared/images/reviews/Recipes.png';
import arrow from '../shared/images/reviews/Menu_arrow.png';



const ReviewsPage = () => {
    return (
        <main className="pl-3 pr-3">
<div className="container">
    <div className="row justify-content-center">

<div className=" d-md-block d-lg-none mt-4" style={{textAlign:"center", margin:"0 0 30px 0"}}>
  <div className="btn-group" style={{backgroundColor:"#22190f", color:"#f8f3dc", borderRadius: "10px"}}>
      <button className="btn btn-custom btn-lg dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{width:"280px", backgroundColor:"#22190f", color:"#f8f3dc"}}>
          <span style={{fontSize:"24px"}}>Reviews</span><br/>
          <span style={{fontSize:"16px"}}>&amp; Testimonials</span>  <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" style={{width:"280px"}}>
          <li style={{height:"30px"}}><a href="/Reviews/">PRESS REVIEWS</a></li>
          <li style={{height:"30px"}}><a href="/Reviews/Testimonials">TESTIMONIALS</a></li>
      </ul>
  </div> 
</div>
    
{/* <!-- // Small Screen Main --> */}
    <section className="col-sm-12  d-md-none">
      <div className="justify-content-center row ">
        <div style={{fontSize: "14px"}}>
          <div className="justify-content-center">
            <div className="row">
              <div className="col-12">
                <img src={johnMartha} className="" alt="" />
              </div>
            </div>
            <div className="col-12 justify-content-center pr-5">
              <p className="content_title boarderBottom">
                JOHN HOWIE & PLANK COOKING ON MARTHA STEWART LIVING
              </p>
              <p>
                "Delicious. This is a very unusual and tasty, tasty
                appetizer!"
              </p>
              <p>"I think we should all try plank cooking."</p>

              <p>
                Martha Stewart - Martha Stewart Living Television Show
              </p>
              <div className="row justify-content-center" >


              <div className="  boarderTop " style={{width:"400px"}}>
                <div className=" dotted_line_right" style={{width:"400px"}}>
                  <h2 className="  boarderBottom content_title " >
                  SIMPLY SEAFOOD MAGAZINE ©
                  </h2>
                </div>
              </div>

<div className="row justify-content-center mb-5 pt-3">
                <div className="" style={{ width:"190px"}}>
                  <p>
                    "Guests will delight in the delectable fragrance of
                    salmon mingled with the aroma of the heated plank."
                  </p>
                  <p style={{fontFamily:"italic"}}>Simply Seafood Magazine</p>
                </div>
                <div className="col-6">
                  <img src={seafood} className="" alt=""/>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <!-- // Large Side Menu --> */}
<div className="row justify-content-center">

<div className="d-none d-lg-block col-3 mt-5">
        <img alt="Page Title" style={{paddingTop:"5px"}} src={recipes} />

            <div style={{textAlign:"center", maxWidth:"225px", margin: "0 auto"}}>

                <div style={{margin:"10px 0px 10px 10px"}} class="dotted_line"></div>

                    <div>
                        <a href="/reviews/">
                            <span class="leftMenuTitle">PRESS REVIEWS</span>
                                <img alt="selected arrow" style={{paddingLeft:"5px", border:"none"}} src={arrow} />
                        </a>
                    </div>
                    <div style={{margin:"10px 0px 10px 10px"}} class="dotted_line"></div>
                    <div>
                        <a href="/reviews/testimonials">
                            <span class="leftMenuTitle">TESTIMONIALS</span>
                        </a>
                    </div>
                    <div style={{margin:"10px 0px 10px 10px"}} class="dotted_line"></div>
            </div>
    </div>




   
{/* <!-- // Large Main --> */}
      <section className="d-none d-md-block col-md-8 col-9 ml-2 mt-5 mb-5 justify-content-center">
        <div className="row ml-2 mt-4 justify-content-center">
          <div id="" className="paperBackground" style={{height: "720px"}}>
          <div className="" style={{width: "620px", fontSize: "14px"}}>          
              <div className="row">
                <div className="col-5 pullLeft">                 
                  <div className="boardBackgroundReview "
                    style={{width: "400px"}}>
                    <img src={johnMartha} className="mb-4" alt="" />
                  </div>                 
                </div>
                <div className="col-6 text-center ml-4 pl-5 pr-3" >
                  <p className="content_title boarderBottom">
                    JOHN HOWIE & PLANK COOKING ON MARTHA STEWART LIVING
                  </p>
                  <p>
                    "Delicious. This is a very unusual and tasty, tasty
                    appetizer!"
                  </p>
                  <p>"I think we should all try plank cooking."</p>

                  <p>
                    Martha Stewart - Martha Stewart Living Television
                    Show
                  </p>
                </div>
              </div>
            
            <div className="boarderTop mt-5 mr-5">
              <p
                className="dotted_line_right boarderBottom mr-5" style={{width: "300px"}}>
                SIMPLY SEAFOOD MAGAZINE ©
              </p>
            </div>
            <div className="row text-center  ">
              <div className="col-7">
                <p>
                  "Guests will delight in the delectable fragrance of
                  salmon mingled with the aroma of the heated plank."
                </p>
                <p>Simply Seafood Magazine</p>
              </div>
              <div className="col-5 " style={{top: "-35px", right: "40px"}} >
                <img src={seafood} className="" alt="" />
              </div>
            </div>
          </div> 
      </div>   
      <div className="d-none d-lg-block">
        <div className="row">  
          <div className="col-3"></div>
          <div className="paperBackgroundBottom col-12 justify-content-center" style={{width: "650px", height: "50px", right: "-32px"}}>      
          </div>
        </div>
      </div> 
      <div className="d-none d-md-block  d-lg-none">
        <div className="paperBackgroundBottom ml-4" style={{width: "640px", height: "50px"}}>      
        </div>
      </div>
      </div>
    </section>
  

</div>
  </div>
  </div>
  
</main>
    );
}

export default ReviewsPage;