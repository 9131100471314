import React from 'react'

import paperBottom from "../shared/images/PaperBackground_Bottom.png";

function PaperBottom() {
    return (
       <>
    <div className="d-none d-lg-block mb-5">
        <div className=" row justify-content-center">
            <figure className="" style={{width: "580px"}}>
                <img src={paperBottom} className="" style={{marginLeft: "115px"}} alt=""/>
            </figure>
        </div>
      </div>

      <div className="d-none d-md-block d-lg-none ">
        <div className=" row justify-content-center">
            <figure className="mr-3" style={{width: "590px"}}>
                <img src={paperBottom}  style={{marginLeft: "3px"}} alt=""/>
            </figure>
        </div>
      </div>
      </>
    )
}

export default PaperBottom
