import React from 'react';
import reviewsHeader from '../shared/images/reviews/Reviews_MenuHeader.png';
import arrow from '../shared/images/reviews/Menu_arrow.png';

const TestimonialsPage = () => {
    return (
        <main class="pl-3 pr-3 container">
    <h2 class="d-none">Testimonials</h2>
    
    <div class="row justify-content-center">

<div className=" d-md-block col-12 d-lg-none mt-4" style={{textAlign:"center", margin:"0 0 30px 0"}}>
  <div className="btn-group" style={{backgroundColor:"#22190f", color:"#f8f3dc", borderRadius: "10px"}}>
      <button className="btn btn-custom btn-lg dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{width:"280px", backgroundColor:"#22190f", color:"#f8f3dc"}}>
          <span style={{fontSize:"24px"}}>Reviews</span><br/>
          <span style={{fontSize:"16px"}}>&amp; Testimonials</span>  <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" style={{width:"280px"}}>
          <li style={{height:"30px"}}><a href="/Reviews/">PRESS REVIEWS</a></li>
          <li style={{height:"30px"}}><a href="/Reviews/Testimonials">TESTIMONIALS</a></li>
      </ul>
  </div> 
</div>



      <section
      className="col-3 d-none d-lg-block text-center mt-5  " style={{height: "100", left: "-20px", fontSize: "14px"}} >     
      <div>
        <img alt="Page Title" style={{paddingTop:"5px"}} src={reviewsHeader} />

            <div style={{textAlign:"center", maxWidth:"225px", margin: "0 auto"}}>

                <div style={{margin:"10px 0px 10px 10px"}} class="dotted_line"></div>

                    <div>
                        <a href="/reviews/">
                            <span class="leftMenuTitle">PRESS REVIEWS</span>
                                <img alt="selected arrow" style={{paddingLeft:"5px", border:"none"}} src={arrow} />
                        </a>
                    </div>
                    <div style={{margin:"10px 0px 10px 10px"}} class="dotted_line"></div>
                    <div>
                        <a href="/reviews/testimonials">
                            <span class="leftMenuTitle">TESTIMONIALS</span>
                        </a>
                    </div>
                    <div style={{margin:"10px 0px 10px 10px"}} class="dotted_line"></div>
                
            </div>
    </div>
</section>





      <section class="col-sm-12 col-md-8 d-md-none ">
        <div class="justify-content-center" style={{ fontSize: "14px", textAlign: "left"}}>
          <div class="justify-content-center">
            <div class="row ">
              {/* <div class="col-7 "></div> */}
              <div class="col-12 ">
                <p class="pt-2">
                  “I must tell you I’m very impressed with the prompt
                  delivery of the four planks I ordered on December 4th
                  and received on December 7th. My husband and I have
                  thoroughly enjoyed our own plank and have experimented
                  quite a bit. I probably cook on it twice a week. Thanks
                  for the great service."
                </p>
                <p class="boarderBottom" style={{fontFamily:"italic"}}>-Becky C</p>
                <p>
                  "Last night, I used your dry herb rub for salmon to
                  enhance the flavor of shrimp. My husband and I enjoyed
                  the flavors that both the cedar plank and herb rub gave
                  the shellfish. But the added benefit was the woodsy
                  aroma throughout the house. It brought me back to my
                  Vermont days, with a wood burning fire place."
                </p>
                <p class="boarderBottom" style={{fontFamily:"italic"}}>-A. Cardello</p>
                <p>
                  "Thank you for a great product. We use it all the time.
                  It might even keep us away from your restaurant since we
                  can make our own great meals!"
                </p>
                <p style={{fontFamily:"italic"}}>-Kyle Ohashi</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="col-8 d-none d-md-block mt-5">
        <div class=" row mt-3 justify-content-center" style={{textAlign: "left"}}>
          <div class="paperBackground">
            <div class="" style={{width: "620px", fontSize: "14px"}}>
              
                <div class="row">
                
                  <div class="col-12 pr-5 pt-3 pl-5">
                    <p class="pt-3">
                      “I must tell you I’m very impressed with the prompt
                      delivery of the four planks I ordered on December
                      4th and received on December 7th. My husband and I
                      have thoroughly enjoyed our own plank and have
                      experimented quite a bit. I probably cook on it
                      twice a week. Thanks for the great service."
                    </p>
                    <p class="boarderBottom" style={{fontFamily:"italic"}}>-Becky C</p>
                    <p>
                      "Last night, I used your dry herb rub for salmon to
                      enhance the flavor of shrimp. My husband and I
                      enjoyed the flavors that both the cedar plank and
                      herb rub gave the shellfish. But the added benefit
                      was the woodsy aroma throughout the house. It
                      brought me back to my Vermont days, with a wood
                      burning fire place."
                    </p>
                    <p class="boarderBottom" style={{fontFamily:"italic"}}>-A. Cardello</p>
                    <p>
                      "Thank you for a great product. We use it all the
                      time. It might even keep us away from your
                      restaurant since we can make our own great meals!"
                    </p>
                    <p style={{fontFamily:"italic"}}>-Kyle Ohashi</p>
                  </div>
             
              </div>
            </div>
          </div>
          
          <div class=" row col-sm-12 col-md-8 mb-5">
            <img
              src=""
              class="paperBottomShop"
              style={{width: "546px"}}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  </main>
    );
}

export default TestimonialsPage;