import {
  ADD_PRODUCT_TO_CART,
  DELETE_PRODUCT_FROM_CART,
  UPDATE_CART_QUANTITY,
  UPDATE_ORDERCARTID,
  GET_CART_LIST,
  CHECKOUT_CART,
  PURCHASE_CART,
} from "../actions/cartActions";

const orderCartId = Number(sessionStorage.getItem("orderCartId"));
const initialState = {
  orderCartId: orderCartId,
  cartList:[],
  customerDetails:{},
};

const cartReducer = function(state = initialState, action) {
  switch (action.type) {
    case CHECKOUT_CART: {
      return {
        ...state,
        customerDetails: {...state.customerDetails, ...action.payload}
      }
    }
    case PURCHASE_CART: {
      return {
        ...state,
        customerDetails:{...state.customerDetails, ...action.payload }
      }
    }
    case GET_CART_LIST: {
      return {
        ...state,
        cartList: action.payload
      }
    }
    case UPDATE_ORDERCARTID: {
      sessionStorage.setItem("orderCartId",action.payload);
      return {
        ...state,
        orderCartId: action.payload,
      }
    }
    case ADD_PRODUCT_TO_CART: {
      return {
        ...state,
        cartList: [
          ...state.cartList,
          action.payload
        ]
      };
    }
    case DELETE_PRODUCT_FROM_CART: {
      const {ProductID } = action.payload;
      return {
        ...state,
        cartList: [
          ...state.cartList.filter(function(item) {return item.ProductID !== ProductID})]
      };
    }
    case UPDATE_CART_QUANTITY: {
      const { ProductID, Qty } = action.payload;
      return {
        ...state,
        cartList: [
          ...state.cartList.filter(function(item) {
            if(item.ProductID === ProductID)
            {
              item.Qty = Qty;
            }
            return item;
          })]
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default cartReducer;
