import React from "react";
import ribs from "../shared/images/recipies/BBQRibs_RecipeImage.png";
import cedarSalmon from "../shared/images/recipies/CedarPlankSalmon_RecipeImage.png";
import maplePork from "../shared/images/recipies/MaplePorkChops_RecipeImage.png";
import mustardChic from "../shared/images/recipies/MustardChicken_RecipeImage.png";
import pumpkinSoup from "../shared/images/recipies/PumpkinSoup_RecipeImage.png";
import recipeHeader from "../shared/images/recipies/Recipe_MenuHeader.png";
import download from "../shared/images/recipies/RecipeDownload_Button.png";

import paperBottom from "../shared/images/PaperBackground_Bottom.png";

const RecipesPage = () => {
  return (
    <>
      <main alt="">
        <div className="container pr-2 pl-2">
          <div className="row justify-content-center">
            <section className="col-3 d-none d-lg-block mt-5">
              <figure className="mt-2">
                <img src={recipeHeader} alt="" />
              </figure>
            </section>

            <section className="col-sm-12 col-md-8 d-md-none mt-4">
              <div className=" row justify-content-center">
                <div alt="" style={{ width: "620px", fontSize: "14px" }}>
                  <div className="row ">
                    <div className="col-12">
                      <div className="pl-3 pr-3 mt-2">
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Alder Plank Roasted Porcini Crusted Salmon with Pesto
                          Rice
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Alder
                          Plank Roasted Porcini Crusted Salmon with Pesto Rice
                          recipe.
                        </p>

                        <a
                          download
                          href="https://plankcooking.com/Content/recipes/pdf/Alder_Plank_Roasted_Porcini_Crusted_Salmon_with_Pesto_Rice.pdf"
                          rel="noreferrer"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Ancho Chili Rubbed Chicken with Corn Relish
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Ancho
                          Chili Rubbed Chicken with Corn Relish recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Ancho_Chili_Rubbed_Chicken_with_Corn_Relish.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Cedar BBQ Plank Roasted Spicy Pecan Halibut
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Cedar BBQ
                          Plank Roasted Spicy Pecan Halibut recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Cedar_BBQ_Plank_Roasted_Spicy_Pecan_Halibut.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Cedar Plank Roasted Mushrooms
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Cedar
                          Plank Roasted Mushrooms recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Cedar_Plank_Roasted_Mushrooms.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Garlic Chicken Dijon
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Garlic
                          Chicken Dijon recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Garlic_Chicken_Dijon.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Maple Roasted Pork Chops and Pearl Onions
                        </p>

                        <div className="row">
                          <div className="col inLine">
                            <p style={{ textAlign: "left" }}>
                              Click the Recipe PDF button to download the Maple
                              Roasted Pork Chops and Pearl Onions recipe.
                            </p>
                            <a
                              download
                              rel="noreferrer"
                              href="https://plankcooking.com/Content/recipes/pdf/Maple_Roasted_Pork_Chops_and_Pearl_Onions.pdf"
                              target="_blank"
                              style={{ textAlign: "left" }}
                            >
                              <figure className="mt-2">
                                <img src={download} alt="" />
                              </figure>
                            </a>
                          </div>

                          <div className=" " style={{ textAlign: "right" }}>
                            <figure className=" col-sm pr-3">
                              <img src={maplePork} alt="" />
                            </figure>
                          </div>
                        </div>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Maple Turkey w/Apple-Pecan Stuffing
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Maple
                          Turkey w/Apple-Pecan Stuffing recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Maple_Roasted_Pork_Chops_and_Pearl_Onions.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Plank Roasted Baby Back Ribs
                        </p>

                        <div className="row">
                          <div className="col inLine">
                            <p style={{ textAlign: "left" }}>
                              Click the Recipe PDF button to download the Plank
                              Roasted Baby Back Ribs recipe.
                            </p>
                            <a
                              download
                              rel="noreferrer"
                              href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Baby_Back_Ribs.pdf"
                              target="_blank"
                              style={{ textAlign: "left" }}
                            >
                              <figure className="mt-2">
                                <img src={download} alt="" />
                              </figure>
                            </a>
                          </div>

                          <div alt="" style={{ textAlign: "right" }}>
                            <figure className="col-sm pr-3">
                              <img src={ribs} alt="" />
                            </figure>
                          </div>
                        </div>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Plank Roasted Dungeness Crab Stuffed Mushrooms
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Plank
                          Roasted Dungeness Crab Stuffed Mushrooms recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Dungeness_Crab_Stuffed_Mushrooms.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Plank Roasted Garlic-Rosemary Pork Chops
                        </p>

                        <div className="row">
                          <div className="col">
                            <p style={{ textAlign: "left" }}>
                              Click the Recipe PDF button to download the Plank
                              Roasted Garlic-Rosemary Pork Chops recipe.
                            </p>
                            <a
                              download
                              rel="noreferrer"
                              href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Garlic_Rosemary_Pork_Chops.pdf"
                              target="_blank"
                              style={{ textAlign: "left" }}
                            >
                              <figure className="mt-2">
                                <img src={download} alt="" />
                              </figure>
                            </a>
                          </div>
                        </div>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Plank Roasted Honey Mustard Chicken
                        </p>
                        <div className="row">
                          <div className="col inLine">
                            <p style={{ textAlign: "left" }}>
                              Click the Recipe PDF button to download the Plank
                              Roasted Honey Mustard Chicken recipe.
                            </p>
                            <a
                              download
                              rel="noreferrer"
                              href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Honey_Mustard_Chicken.pdf"
                              target="_blank"
                              style={{ textAlign: "left" }}
                            >
                              <figure className="mt-2">
                                <img src={download} alt="" />
                              </figure>
                            </a>
                          </div>

                          <div alt="" style={{ textAlign: "right" }}>
                            <figure className="col-sm pr-3">
                              <img src={mustardChic} alt="" />
                            </figure>
                          </div>
                        </div>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Plank Roasted Pumpkin Soup
                        </p>

                        <div className="row">
                          <div className="col">
                            <p style={{ textAlign: "left" }}>
                              Click the Recipe PDF button to download the Plank
                              Roasted Pumpkin Soup recipe.
                            </p>
                            <a
                              download
                              rel="noreferrer"
                              href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Pumpkin_Soup.pdf"
                              target="_blank"
                              style={{ textAlign: "left" }}
                            >
                              <figure className="mt-2">
                                <img src={download} alt="" />
                              </figure>
                            </a>
                          </div>
                          <div alt="" style={{ textAlign: "right" }}>
                            <figure className="col-sm pr-3">
                              <img src={pumpkinSoup} alt="" />
                            </figure>
                          </div>
                        </div>

                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Plank Roasted Sausage Stuffed Zucchini
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Plank
                          Roasted Sausage Stuffed Zucchini recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Sausage_Stuffed_Zucchini.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Porcini Chicken with Lemon-Thyme Zucchini
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Porcini
                          Chicken with Lemon-Thyme Zucchini recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Porcini_Chicken_with_Lemon_Thyme_Zucchini.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Pork Tenderloin with Herb Stuffing and Onions
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Pork
                          Tenderloin with Herb Stuffing and Onions recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Pork_Tenderloin_with_Herb_Stuffing_and_Onions.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Salmon Oregano with Lemon Caper Dressing
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Salmon
                          Oregano with Lemon Caper Dressing recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Salmon_Oregano_with_Lemon_Caper_Dressing.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Salmon with Basil-Garlic Rub
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Salmon
                          with Basil-Garlic Rub recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Salmon_with_Basil_Garlic_Rub.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Sea Bass with Ginger
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Sea Bass
                          with Ginger recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Sea_Bass_with_Ginger.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          Teriyaki Chicken Wings - Appetizer
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the Teriyaki
                          Chicken Wings - Appetizer recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/Teriyaki_Chicken_Wings_Appetizer.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                        </a>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          The Original Cedar Plank Roasted Salmon
                        </p>
                        <div className="row">
                          <div className="col">
                            <p style={{ textAlign: "left" }}>
                              Click the Recipe PDF button to download the The
                              Original Cedar Plank Roasted Salmon recipe.
                            </p>
                            <a
                              download
                              rel="noreferrer"
                              href="https://plankcooking.com/Content/recipes/pdf/The_Original_Cedar_Plank_Roasted_Salmon.pdf"
                              target="_blank"
                              style={{ textAlign: "left" }}
                            >
                              <figure className="mt-2">
                                <img src={download} alt="" />
                              </figure>
                            </a>
                          </div>
                          <div alt="" style={{ textAlign: "right" }}>
                            <figure className="col-sm pr-3">
                              <img src={cedarSalmon} alt="" />
                            </figure>
                          </div>
                        </div>
                        <p
                          className="boarderBottom boarderTop"
                          style={{ textAlign: "left" }}
                        >
                          White King Salmon with Roasted Fennel
                        </p>
                        <p style={{ textAlign: "left" }}>
                          Click the Recipe PDF button to download the White King
                          Salmon with Roasted Fennel recipe.
                        </p>
                        <a
                          download
                          rel="noreferrer"
                          href="https://plankcooking.com/Content/recipes/pdf/White_King_Salmon_with_Roasted_Fennel.pdf"
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          <figure className="mt-2">
                            <img src={download} alt="" />
                          </figure>
                          <hr />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className=" col-md-12 col-lg-9 d-none d-md-block mt-5">
              <div className=" row mt-3 justify-content-center">
                <div className="paperBackground pt-3 ">
                  <div alt="" style={{ width: "620px", fontSize: "14px" }}>
                    <div className="row ">
                      <div className="col-12">
                        <div className=" mt-2 pr-3 pl-3">
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Alder Plank Roasted Porcini Crusted Salmon with
                            Pesto Rice
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Alder
                            Plank Roasted Porcini Crusted Salmon with Pesto Rice
                            recipe.
                          </p>

                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Alder_Plank_Roasted_Porcini_Crusted_Salmon_with_Pesto_Rice.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure>
                              <img src={download} alt="" />
                            </figure>
                          </a>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Ancho Chili Rubbed Chicken with Corn Relish
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Ancho
                            Chili Rubbed Chicken with Corn Relish recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Ancho_Chili_Rubbed_Chicken_with_Corn_Relish.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Cedar BBQ Plank Roasted Spicy Pecan Halibut
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Cedar
                            BBQ Plank Roasted Spicy Pecan Halibut recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Cedar_BBQ_Plank_Roasted_Spicy_Pecan_Halibut.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Cedar Plank Roasted Mushrooms
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Cedar
                            Plank Roasted Mushrooms recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Cedar_Plank_Roasted_Mushrooms.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Garlic Chicken Dijon
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Garlic
                            Chicken Dijon recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Garlic_Chicken_Dijon.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Maple Roasted Pork Chops and Pearl Onions
                          </p>

                          <div className="row">
                            <div className="col-6 inLine">
                              <p style={{ textAlign: "left" }}>
                                Click the Recipe PDF button to download the
                                Maple Roasted Pork Chops and Pearl Onions
                                recipe.
                              </p>
                              <a
                                download
                                rel="noreferrer"
                                href="https://plankcooking.com/Content/recipes/pdf/Maple_Roasted_Pork_Chops_and_Pearl_Onions.pdf"
                                target="_blank"
                                style={{ textAlign: "left" }}
                              >
                                <figure className="mt-2">
                                  <img src={download} alt="" />
                                </figure>
                              </a>
                            </div>

                            <div
                              className="col-6"
                              style={{ textAlign: "right" }}
                            >
                              <figure className="pr-3">
                                <img src={maplePork} alt="" />
                              </figure>
                            </div>
                          </div>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Maple Turkey w/Apple-Pecan Stuffing
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Maple
                            Turkey w/Apple-Pecan Stuffing recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Maple_Roasted_Pork_Chops_and_Pearl_Onions.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Baby Back Ribs
                          </p>

                          <div className="row">
                            <div className="col-6 inLine">
                              <p style={{ textAlign: "left" }}>
                                Click the Recipe PDF button to download the
                                Plank Roasted Baby Back Ribs recipe.
                              </p>
                              <a
                                download
                                rel="noreferrer"
                                href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Baby_Back_Ribs.pdf"
                                target="_blank"
                                style={{ textAlign: "left" }}
                              >
                                <figure className="mt-2">
                                  <img src={download} alt="" />
                                </figure>
                              </a>
                            </div>

                            <div
                              className="col-6"
                              style={{ textAlign: "right" }}
                            >
                              <figure className="pr-3">
                                <img src={ribs} alt="" />
                              </figure>
                            </div>
                          </div>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Dungeness Crab Stuffed Mushrooms
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Plank
                            Roasted Dungeness Crab Stuffed Mushrooms recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Dungeness_Crab_Stuffed_Mushrooms.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Garlic-Rosemary Pork Chops
                          </p>

                          <div className="row">
                            <div className="col-6">
                              <p style={{ textAlign: "left" }}>
                                Click the Recipe PDF button to download the
                                Plank Roasted Garlic-Rosemary Pork Chops recipe.
                              </p>
                              <a
                                download
                                rel="noreferrer"
                                href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Garlic_Rosemary_Pork_Chops.pdf"
                                target="_blank"
                                style={{ textAlign: "left" }}
                              >
                                <figure className="mt-2">
                                  <img src={download} alt="" />
                                </figure>
                              </a>
                            </div>
                            <div
                              className="col-6"
                              style={{ textAlign: "right" }}
                            >
                              <figure className="pr-3">
                                <img src={mustardChic} alt="" />
                              </figure>
                            </div>
                          </div>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Honey Mustard Chicken
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Plank
                            Roasted Honey Mustard Chicken recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Honey_Mustard_Chicken.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Pesto Stuffed Chicken Breast
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Plank
                            Roasted Pesto Stuffed Chicken Breast recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Pesto_Stuffed_Chicken_Breast.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Pumpkin Soup
                          </p>

                          <div className="row">
                            <div className="col-6">
                              <p style={{ textAlign: "left" }}>
                                Click the Recipe PDF button to download the
                                Plank Roasted Pumpkin Soup recipe.
                              </p>
                              <a
                                download
                                rel="noreferrer"
                                href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Pumpkin_Soup.pdf"
                                target="_blank"
                                style={{ textAlign: "left" }}
                              >
                                <figure className="mt-2">
                                  <img src={download} alt="" />
                                </figure>
                              </a>
                            </div>
                            <div
                              className="col-6"
                              style={{ textAlign: "right" }}
                            >
                              <figure className="pr-3">
                                <img src={pumpkinSoup} alt="" />
                              </figure>
                            </div>
                          </div>

                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Plank Roasted Sausage Stuffed Zucchini
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Plank
                            Roasted Sausage Stuffed Zucchini recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Plank_Roasted_Sausage_Stuffed_Zucchini.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Porcini Chicken with Lemon-Thyme Zucchini
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Porcini
                            Chicken with Lemon-Thyme Zucchini recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Porcini_Chicken_with_Lemon_Thyme_Zucchini.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Pork Tenderloin with Herb Stuffing and Onions
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Pork
                            Tenderloin with Herb Stuffing and Onions recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Pork_Tenderloin_with_Herb_Stuffing_and_Onions.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Salmon Oregano with Lemon Caper Dressing
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Salmon
                            Oregano with Lemon Caper Dressing recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Salmon_Oregano_with_Lemon_Caper_Dressing.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Salmon with Basil-Garlic Rub
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Salmon
                            with Basil-Garlic Rub recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Salmon_with_Basil_Garlic_Rub.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Sea Bass with Ginger
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Sea Bass
                            with Ginger recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Sea_Bass_with_Ginger.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            Teriyaki Chicken Wings - Appetizer
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the Teriyaki
                            Chicken Wings - Appetizer recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/Teriyaki_Chicken_Wings_Appetizer.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            The Original Cedar Plank Roasted Salmon
                          </p>
                          <div className="row">
                            <div className="col-6">
                              <p style={{ textAlign: "left" }}>
                                Click the Recipe PDF button to download the The
                                Original Cedar Plank Roasted Salmon recipe.
                              </p>
                              <a
                                download
                                rel="noreferrer"
                                href="https://plankcooking.com/Content/recipes/pdf/The_Original_Cedar_Plank_Roasted_Salmon.pdf"
                                target="_blank"
                                style={{ textAlign: "left" }}
                              >
                                <figure className="mt-2">
                                  <img src={download} alt="" />
                                </figure>
                              </a>
                            </div>
                            <div
                              className="col-6"
                              style={{ textAlign: "right" }}
                            >
                              <figure className="pr-3">
                                <img src={cedarSalmon} alt="" />
                              </figure>
                            </div>
                          </div>
                          <p
                            className="boarderBottom boarderTop"
                            style={{ textAlign: "left" }}
                          >
                            White King Salmon with Roasted Fennel
                          </p>
                          <p style={{ textAlign: "left" }}>
                            Click the Recipe PDF button to download the White
                            King Salmon with Roasted Fennel recipe.
                          </p>
                          <a
                            download
                            rel="noreferrer"
                            href="https://plankcooking.com/Content/recipes/pdf/White_King_Salmon_with_Roasted_Fennel.pdf"
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            <figure className="mt-2">
                              <img src={download} alt="" />
                            </figure>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Paper Bottom --> */}
                <div className="d-none d-lg-block ">
                  <div className=" row">
                    <figure className="ml-2" style={{ width: "640px" }}>
                      <img src={paperBottom} className="ml-1" alt=""/>
                    </figure>
                  </div>
                </div>
                <div className="d-none d-md-block d-lg-none ">
                  <div className=" row">
                    <figure alt="">
                      <img src={paperBottom} className="ml-3" alt=""/>
                    </figure>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </div>
      </main>
    </>
  );
};

export default RecipesPage;
