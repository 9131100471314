
export const GET_CART_LIST = "GET_CART_LIST";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const UPDATE_CART_QUANTITY = "UPDATE_CART_QUANTITY";
export const UPDATE_ORDERCARTID = "UPDATE_ORDERCARTID";
export const CHECKOUT_CART = "CHECKOUT_CART";
export const PURCHASE_CART = "PURCHASE_CART";



export const addProductToCart = (payload) => dispatch => {
  // post("http://localhost:5000/api/cart/add", {productId: payload.ProductID, orderCartId: payload.orderCartId, qty: payload.Qty})
  // .then(res => {
    // console.log(res);
    // if(payload.orderCartId === 0)
    // {
    //     dispatch({
    //       type:UPDATE_ORDERCARTID,
    //       payload:res.data.recordset[0].OrderCartId,
    //     })
    // }
    dispatch({
      type: ADD_PRODUCT_TO_CART,
      payload: payload
    });
  // });
};

export const deleteProductFromCart = (payload) => dispatch => {
  // axios
  //   .delete("http://localhost:5000/api/cart/delete", {data : {productId: payload.ProductID, orderCartId: payload.orderCartId}})
  //   .then(res => {
      dispatch({
        type: DELETE_PRODUCT_FROM_CART,
        payload: payload
      });
    // });
};

export const updateCartQuantity = (payload) => dispatch => {
  // axios
  // .put("http://localhost:5000/api/cart/update", {productId: payload.ProductID, orderCartId: payload.orderCartId, qty: payload.Qty})
  // .then(res => {
    dispatch({
      type: UPDATE_CART_QUANTITY,
      payload: payload
    });
  // });
};


export const checkoutCart =(payload) => dispatch => {
  // axios
  // .put("http://localhost:5000/api/cart/checkout/update", payload)
  // .then(res => {
    dispatch({
      type: CHECKOUT_CART,
      payload: payload,
    })
  // })
}


export const purchaseCart =(payload) => dispatch => {
  // axios
  // .put("http://localhost:5000/api/cart/purchase", payload)
  // .then(res => {
    dispatch({
      type: PURCHASE_CART,
      payload: payload,
    })
  // })
}