import React from 'react'
import {useSelector} from 'react-redux';

import ProductSection from './ProductSection'

import CategorySideMenu from "./CategorySideMenu";
import PaperBottom from "./PaperBottom";

function BBQPlanksPage() {

  const products = useSelector(state => state.products);
  const categories = useSelector(state => state.categories);

  return (
    <>
      <div className="row  justify-content-center">
        {categories.map((category) => (
          <>
            {category.categoryId === 4 ? (
              <CategorySideMenu categoryImagePath={category.imagePath} 
              // categoryDescription={categoryDescription} 
              />
            ) : (
              ""
            )}
          </>
        ))}
      </div>
      {products.map((product) => (
        <>
          {product.categoryId === 4 ? (
            <ProductSection
              netweight={product.ounces}
              productId={product.productId}
              productName={product.name}
              productDescription={product.description}
              priceDescription={product.priceDescription}
              productImagePath={product.imagePath}
              price={product.price}
            />
          ) : (
            ""
          )}
        </>
      ))}
      <PaperBottom />
    </>
  )
}

export default BBQPlanksPage
