import React from 'react'
import CartItemsAdded from '../cart/CartItemsAdded';

const ProductSection = (props) => {
    return (
      <div className="container pl-4 pr-4">
        <div className="row justify-content-center ">
          <div
            className="col-12 col-sm-12 d-md-none"
            style={{ width: "610px", fontSize: "14px" }}
          >
            <div className="content_title boarderTop mt-1">
              <div
                className="col-12 dotted_line_right dotted_line_bottom  "
                style={{ textAlign: "left" }}
              >
                {props.productName}
              </div>
            </div>
            <div className="row pl-2" style={{ fontSize: "14px" }}>
              <div className="col-7" style={{ textAlign: "left" }}>
                {props.productDescription}
              </div>
              <div className="col-5 ">
                <p>
                  {props.priceDescription}{" "}
                  {props.categoryId === 1 ? (
                    <>Net weight {props.netweight}</>
                  ) : (
                    ""
                  )}{" "}
                </p>

                <CartItemsAdded
                  orderItemId={props.orderItemId}
                  orderCartId={props.orderCartId}
                  name={props.productName}
                  productId={props.productId}
                  price={props.price}
                />

                <figure className="justify-content-center">
                  <img
                    src={props.productImagePath}
                    alt={props.productName}
                    className=" mr-0"
                  />
                </figure>
             
              </div>
            </div>
          </div>

          <div className="col-3 pl-5"></div>
          <div className="d-none d-md-block col-md-12 col-lg-9 ">
            <div className="row justify-content-center  ml-1">
              <div className="paperBackground ml-3 ">
                <div className="" style={{ width: "610px", fontSize: "14px" }}>
                  <section className="p-2">
                    <div
                      className=" content_title boarderTop "
                      style={{ width: "580px" }}
                    >
                      <div
                        className="col-8 dotted_line_right dotted_line_bottom "
                        style={{ textAlign: "left" }}
                      >
                        {props.productName}
                      </div>
                    </div>
                    <div className="row pl-2" style={{ fontSize: "14px" }}>
                      <div className="col-7 mb-3" style={{ textAlign: "left" }}>
                        <p>{props.productDescription}</p>
                      </div>
                      <div className="col-5">
                        <p>{props.priceDescription}</p>

                        {props.categoryId === 1 ? (
                          <p>NET WT. {props.productOunces} oz's</p>
                        ) : (
                          ""
                        )}

                        <div className="mb-2">
                          Quantity:
                          <CartItemsAdded
                            name={props.productName}
                            productId={props.productId}
                            price={props.price}
                          />
                        </div>

                        <figure>
                          <img src={props.productImagePath} alt="" />
                        </figure>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ProductSection
