import React from 'react';
import {Route, Switch } from 'react-router-dom';
// import { useDispatch} from 'react-redux';
// import {Store} from "./../redux/Store";
// import {getCartList} from "./../redux/actions/cartActions";

import Header from './shared/Header';
import Footer from './shared/Footer';
import HomePage from './home/HomePage';
import AboutPage from './about/AboutPage';
import ContactPage from './contact/ContactPage';
import CartPage from './cart/CartPage';
import CartPageTwo from './cart/CartPageTwo';
import CartPageThree from './cart/CartPageThree';
import CartPageFour from './cart/CartPageFour';
import RecipesPage from './recipes/RecipesPage';
import ReviewsPage from './reviews/ReviewsPage';
import ShopPage from './shop/ShopPage';
import TestimonialsPage from './reviews/TestimonialsPage';

import CookBooksPage from './shop/CookBooksPage';
import BakingPlanksPage from './shop/BakingPlanksPage';
import SpiceRubsPage from './shop/SpiceRubsPage';
import BBQPlanksPage from './shop/BBQPlanksPage';
import Nutdriver from './shop/Nutdriver';

import "bootstrap/dist/js/bootstrap.bundle";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../components/shared/Global.css";




function App() {

    return (

        <div className="">
            <Header />
            <Switch>

            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route exact path="/cart" component={CartPage} />
            <Route exact path="/cart/checkout" component={CartPageTwo} />
            <Route  exact path="/cart/checkout/update" component={CartPageThree} />
            <Route  exact path="/cart/checkout/purchase" component={CartPageFour} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/recipes" component={RecipesPage} />
            <Route exact path="/reviews" component={ReviewsPage} />
            <Route exact path="/shop" component={ShopPage} />
            <Route path="/shop/cookbooks" component={CookBooksPage} />
            <Route path="/shop/bakingplanks" component={BakingPlanksPage} />
            <Route path="/shop/spicerubs" component={SpiceRubsPage} />
            <Route path="/shop/bbqplanks" component={BBQPlanksPage} />
            <Route path="/shop/nutdriver" component={Nutdriver} />
            <Route path="/reviews/testimonials" component={TestimonialsPage} />

            </Switch>
            <Footer />
        </div>

    )
}

export default App;