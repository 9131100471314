import React from 'react';
import shopHeader from "../shared/images/shop/Shop_MenuHeader.png";
import { NavLink } from 'react-router-dom'


function CategorySideMenu(props) {
    return (
      <div className="container ">
        <div className="row">
          
          <div className="dropdown col-12 d-lg-none text-center center mt-3">
            <button
              className="btn dropdown-toggle banner"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ width: "250px", color: "#9c7245" }}
            >
              <h2>Shop</h2>
            </button>
            <div
              className="dropdown-menu "
              aria-labelledby="dropdownMenuButton"
            >
              <NavLink 
                className="nav-link-bottom boarderBottom dropdown-item"
                to="/Shop/Spicerubs"
              >
                SPICE RUBS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom boarderBottom dropdown-item"
                to="/Shop/Cookbooks"
              >
                COOKBOOKS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom boarderBottom dropdown-item"
                to="/Shop/Bakingplanks"
              >
                BAKING PLANKS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom boarderBottom dropdown-item"
                to="/Shop/BBQplanks"
              >
                BBQ PLANKS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom boarderBottom dropdown-item"
                to="/Shop/Nutdriver"
              >
                NUT DRIVER
              </NavLink> 
            </div>
          </div>

          <section
            className="col-3 d-none d-lg-block center mt-5 pr-5"
            style={{ height: "100px", fontSize: "14px" }}
          >
            <ul className="hideUlDot " style={{ width: "250px" }}>
              <figure>
                <img src={shopHeader} alt="" className="mr-5 pr-5" />
              </figure>

              <div
                style={{
                  textAlign: "center",
                  maxWidth: "225px",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{ margin: "10px 0px 10px 10px" }}
                  className="dotted_line"
                ></div>

                <div>
                  <NavLink to="/Shop/SpiceRubs">
                    <span className="leftMenuTitle">SPICE RUBS</span>
                  </NavLink>
                </div>
                <div
                  style={{ margin: "10px 0px 10px 10px" }}
                  className="dotted_line"
                ></div>
                <div>
                  <NavLink to="/Shop/Cookbooks">
                    <span className="leftMenuTitle">COOKBOOKS</span>
                  </NavLink>
                </div>
                <div
                  style={{ margin: "10px 0px 10px 10px" }}
                  className="dotted_line"
                ></div>
                <div>
                  <NavLink to="/Shop/Bakingplanks">
                    <span className="leftMenuTitle">BAKING PLANKS</span>
                  </NavLink>
                </div>
                <div
                  style={{ margin: "10px 0px 10px 10px" }}
                  className="dotted_line"
                ></div>
                <div>
                  <NavLink to="/Shop/BBQplanks">
                    <span className="leftMenuTitle">BBQ PLANKS</span>
                  </NavLink>
                </div>
                <div
                  style={{ margin: "10px 0px 10px 10px" }}
                  className="dotted_line"
                ></div>
                <div>
                  <NavLink to="/Shop/Nutdriver">
                    <span className="leftMenuTitle">NUT DRIVER</span>
                  </NavLink>
                </div>
                <div
                  style={{ margin: "10px 0px 10px 10px" }}
                  className="dotted_line"
                ></div>
              </div>
            </ul>
          </section>

          <div className="d-none d-md-block col-md-12 col-lg-9 mt-5 ">
            <div className="row justify-content-center pl-4">
              <div className="boardBackgroundShop">
                <figure>
                  <img
                    src={props.categoryImagePath}
                    className=" "
                    alt=""
                    style={{
                      position: "relative",
                      top: "-10px",
                      left: "0px",
                    }}
                  />
                </figure>
              </div>
            </div>
          </div>

          <div className="col-12 d-md-none mt-5 pr-5 pl-5">
            <div className="row justify-content-center">
              <figure>
                <img
                  src={props.categoryImagePath}
                  className="img-fluid "
                  alt=""
                  style={{
                    position: "relative",
                    top: "-10px",
                    left: "0px",
                  }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CategorySideMenu
