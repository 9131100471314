import React from 'react';
import {render} from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {Provider} from 'react-redux';
import { Store } from './redux/Store';

import App from './components/App';


import "../src/components/shared/Global.css";



render(
  <Provider store={Store} >
  <Router>
    <App />
  </Router>
  </Provider>,
  document.getElementById('root')
);