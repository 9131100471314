import React, {useEffect, useState} from 'react'

import {Store} from './../../redux/Store';

const CartPageFour = () => {
const [carts, setCarts] = useState([]);
const [subtotal, setSubTotal] = useState(0);
const [customer, setCustomer] = useState({});

  useEffect(() => {
    const store = Store.getState();
      console.log(store.cart);
      setCarts(store.cart.cartList);
      setSubTotal(store.cart.cartList.reduce( function(cnt,o){ return cnt + (o.Price * o.Qty); }, 0));
      setCustomer(store.cart.customerDetails);
  }, [])
  
  const renderedCarts = carts.map((cart) => (
  
    <tr className="scope">
      <td
        style={{
          width: "500px",
          textAlign: "left",
          padding: "5px",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
          
      >
        {cart.Name}
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className="ng-binding"
      >
        ${cart.Price}
      </td>
      <td
        style={{
          width: "100px",
          padding: "5px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
      >
        {cart.Qty}
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
          borderRight: "1px dashed #929292",
        }}
      >
        <div style={{ paddingTop: "10px" }} className="ng-binding">
          ${cart.Qty * cart.Price}
        </div>
        <div
          style={{ paddingTop: "10px", paddingBottom: "5px", textAlign: "center", }}
        >
        </div>
      </td>
    </tr>
  ));

  return (
    <main className="pl-3 pr-3 mb-5">
      <div className="container mt-5">
        <div className="row  cartBackground ml-5 cartFont ">
          <div className="col-12 pt-2 pb-2">
            <p> Order Recipt Step 4 of 4</p>
          </div>
        </div>

        <div className="row  lightCartBackground ml-5 cartFont">
          <div
            className="col-6 np"
            style={{
              borderLeft: "1px dashed #929292",
              borderBottom: "1px dashed #929292",
              borderRight: "1px dashed #929292",
            }}
          >
            <div className="p-1 cartBackground">
              <p>Billing Address</p>
            </div>
          </div>
          <div
            className="col-6 np"
            style={{
              borderBottom: "1px dashed #929292",
              borderRight: "1px dashed #929292",
            }}
          >
            <div className=" p-1 cartBackground">
              <p>Shipping Address</p>
            </div>
          </div>

          <div className="col np">
            <table
              border="0"
              cellPadding="5"
              cellSpacing="5"
              width="100%"
              className="formBorder"
              style={{ color: "#4b290c" }}
            >
              <tbody
                style={{
                  borderLeft: "1px dashed #929292",
                  borderBottom: "1px dashed #929292",
                  borderRight: "1px dashed #929292",
                }}
              >
                <tr className="mb-5 lightCartBackground">
                  <td className="moveRight formPadding">Name:</td>
                  <td className="formPadding center">
                    {customer.firstNameBInput}
                    {customer.lastNameBInput}
                  </td>
                </tr>
                <tr className="lightCartBackground">
                  <td className="moveRight formPadding">Address:</td>
                  <td className="formPadding center">
                    <p className="pr-1">
                      {customer.addressBInput} {customer.cityBInput}{" "}
                      {customer.stateBInput} {customer.zipBInput}
                    </p>
                  </td>
                </tr>

                <tr className="lightCartBackground">
                  <td className="moveRight formPadding">Phone:</td>
                  <td className="formPadding center">{customer.phoneBInput}</td>
                </tr>
                <tr className="lightCartBackground">
                  <td className="moveRight formPadding">Email:</td>
                  <td className="formPadding center">{customer.emailBInput}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col np">
            <table
              border="0"
              cellPadding="5"
              cellSpacing="5"
              width="100%"
              className="formBorder"
              style={{ color: "#4b290c" }}
            >
              <tbody
                style={{
                  borderBottom: "1px dashed #929292",
                  borderRight: "1px dashed #929292",
                }}
              >
                <tr className="mb-5 lightCartBackground">
                  <td className=" formPadding">Name:</td>
                  <td className="formPadding center">
                    {customer.firstName}
                    {customer.lastName}
                  </td>
                </tr>
                <tr className="lightCartBackground ">
                  <td className=" formPadding">Address:</td>
                  <td className="formPadding center">
                    <p className="stepThreeSpacer">
                      {customer.address} {customer.city} {customer.state}{" "}
                      {customer.zip}
                    </p>
                  </td>
                </tr>

                <tr className="lightCartBackground">
                  <td className=" formPadding">Phone:</td>
                  <td className="formPadding center">{customer.phone}</td>
                </tr>
                <tr className="lightCartBackground">
                  <td className=" formPadding">Email:</td>
                  <td className="formPadding center">{customer.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row  lightCartBackground ml-5 cartFont">
          <div
            style={{
              backgroundColor: "#e8e3c8",
              margin: "15px 0 0 0",
              padding: "1px",
            }}
            className="container"
          >
            <table border="0" cellPadding="0" cellSpacing="0" width="100%">
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "500px",
                      height: "25px",
                      textAlign: "left",
                      padding: "5px",
                      backgroundColor: "#d2be9d",
                      color: "#4b290c",
                      fontWeight: "bold",
                      borderTop: "1px dashed #929292",
                      borderLeft: "1px dashed #929292",
                    }}
                  >
                    Shipping to You
                  </td>
                  <td
                    className="cartTableLabel"
                    style={{ borderLeft: "1px dashed #929292" }}
                  >
                    Price
                  </td>
                  <td
                    className="cartTableLabel"
                    style={{ borderLeft: "1px dashed #929292" }}
                  >
                    Quantity
                  </td>
                  <td
                    className="cartTableLabel "
                    style={{ borderLeft: "1px dashed #929292" }}
                  >
                    Total
                  </td>
                </tr>

                {renderedCarts}

                <tr>
                  <td
                    style={{
                      width: "500px",
                      textAlign: "left",
                      padding: "5px",
                      color: "#4b290c",
                      borderTop: "1px dashed #929292",
                      borderLeft: "1px dashed #929292",
                      fontWeight: "bold",
                    }}
                  ></td>
                  <td
                    style={{
                      width: "100px",
                      paddingTop: "5px",
                      height: "35px",
                      color: "#4b290c",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderTop: "1px dashed #929292",
                    }}
                  >
                    &nbsp;
                  </td>
                  <td
                    style={{
                      width: "100px",
                      height: "35px",
                      color: "#4b290c",
                      fontWeight: "bold",
                      textAlign: "right",
                      borderTop: "1px dashed #929292",
                    }}
                  >
                    Subtotal:{" "}
                  </td>

                  <td
                    style={{
                      width: "100px",
                      height: "35px",
                      color: "#4b290c",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderTop: "1px dashed #929292",
                      borderRight: "1px dashed #929292",
                    }}
                  >
                    ${subtotal}
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>

          <div
            className="col-11 d-flex justify-content-end"
            style={{ borderLeft: "1px dashed #929292" }}
          >
            <p className="pr-2">*Shipping & Handling: </p>
          </div>
          <div
            className="col-1 pr-5"
            style={{ borderRight: "1px dashed #929292" }}
          >
            ${customer.shippingCost}
          </div>

          <div
            className="col-9 p-2"
            style={{
              borderBottom: "1px dashed #929292",
              borderLeft: "1px dashed #929292",
            }}
          ></div>
          <div
            className="col-1 p-2 pl-5"
            style={{ borderBottom: "1px dashed #929292", textAlign: "right " }}
          >
            Total
          </div>
          <div
            className="col-2 p-2 pl-5"
            style={{
              borderBottom: "1px dashed #929292",
              borderRight: "1px dashed #929292",
            }}
          >
            ${subtotal + parseInt(Number(customer.shippingCost))}
          </div>

          <p className="col-12 d-flex justify-content-end">
            *Shipping durations are estimates and not a guarantee
          </p>
        </div>

        <div className="row  lightCartBackground ml-5 cartFont">
          <div
            className="col-12 "
            style={{ border: "1px dashed #929292", backgroundColor: "#D2BE9D" }}
          >
            <p>Extra Comments</p>
          </div>
          <div
            className="col-12"
            style={{
              borderLeft: "1px dashed #929292",
              borderBottom: "1px dashed #929292",
              borderRight: "1px dashed #929292",
            }}
          >
            <p className="mt-1">{customer.notes}</p>
          </div>
        </div>

        <div className="row lightCartBackground ml-5  mt-3 cartFont text-left">
          <div className="col-12 center cartBackground">
            <p>Payment Information</p>
          </div>

          <div className="col-12  lightCartBackground">
            <p>Confirmation Code: {customer.transactionId}</p>
          </div>
          <div className="col-12 lightCartBackground">
            <p>
              Card Number:
              {customer.ccType} ***7589
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CartPageFour;
