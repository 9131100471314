import React from "react";
import { useSelector } from "react-redux";

import ProductSection from "./ProductSection";
import CategorySideMenu from "./CategorySideMenu";
import PaperBottom from "./PaperBottom";

const CookBooksPage = () => {
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);

  return (
    <>
      <div className="row  justify-content-center">
        {categories.map((category) => (
          <>
            {category.categoryId === 2 ? (
              <CategorySideMenu categoryImagePath={category.imagePath} />
            ) : (
              ""
            )}
          </>
        ))}
      </div>
      {products.map((product) => (
        <>
          {product.categoryId === 2 ? (
            <ProductSection
              netweight={product.ounces}
              productId={product.productId}
              productName={product.name}
              productDescription={product.description}
              priceDescription={product.priceDescription}
              productImagePath={product.imagePath}
              qty={product.qty}
              price={product.price}
              orderCartId={product.orderCartId}
              orderItemId={product.orderItemId}
            />
          ) : (
            ""
          )}
        </>
      ))}
      <PaperBottom />
    </>
  );
};

export default CookBooksPage;
