import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import checkout from '../../components/shared/images/cart/Checkout_button.png';
import continueShopping from '../../components/shared/images/cart/ContinueShopping_button.png';
import remove from '../../components/shared/images/cart/Remove_Button.png';
import {Store} from './../../redux/Store';
import loader from '../shared/images/cart/ajax-loader.gif';
import {updateCartQuantity, deleteProductFromCart} from "./../../redux/actions/cartActions";


const CartPage = () => {

  const dispatch = useDispatch();
  const [carts, setCarts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [subtotal, setSubTotal] = useState(0);

const deleteButtonClicked = (payload) => {
  setLoading(true);
  dispatch(deleteProductFromCart({
    ProductID: payload.ProductID,
    orderCartId: payload.orderCartId,
  })
  )
        const _cart = carts.filter(cart => cart.ProductID !== payload.ProductID);
        setCarts(_cart);
        setSubTotal(_cart.reduce( function(cnt,o){ return cnt + (o.Price * o.Qty); }, 0));
        setLoading(false);
    }

const updateProductQuantity = (payload) => {
  setLoading(true);
  dispatch(updateCartQuantity({
    Qty: payload.Qty,
    ProductID: payload.ProductID,
    orderCartId: payload.orderCartId,
  })
  )
  const _cart = carts.filter((item) => 
    {
      if(item.ProductID === payload.ProductID) {
        item.Qty = payload.Qty;
      }
      return item;
    });
    setCarts(_cart);
    setSubTotal(_cart.reduce( function(cnt,o){ return cnt + (o.Price * o.Qty); }, 0));
    setLoading(false);
}
  useEffect(() => {
    const store = Store.getState();
      setCarts(store.cart.cartList);
      setSubTotal(store.cart.cartList.reduce( function(cnt,o){ return cnt + (o.Price * o.Qty); }, 0));
  }, [])
 
  
  let count = carts.length
  // let productTotal = 0
  


  const renderedCarts = carts.map((cart) => (
    <tr className="ng-scope">
      <td
        style={{
          width: "500px",
          textAlign: "left",
          padding: "5px",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className=""
      >
        {cart.Name}
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className="ng-binding"
      >
        ${cart.Price}
      </td>
      <td
        style={{
          width: "100px",
          padding: "5px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
      >
        <input
          className="UpdateQty ng-pristine isUntouched isValid is-not-empty isValidMaxLength"
          style={{ width: "35px", height: "25px", border: "1px solid #929292", textAlign: "center", }}
          type="number"
          onChange={(e) => {
            const store = Store.getState();
            if(cart.Qty !== e.target.value)
            {
                updateProductQuantity({
                  Name: cart.Name,
                  Qty: e.target.value,
                  Price: cart.Price,
                  ProductID: cart.ProductID,
                  orderCartId: store.cart.orderCartId,
                })
            }
          }}
          minLength="1"
          maxLength="2"
          value={cart.Qty}
        />
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
          borderRight: "1px dashed #929292",
        }}
      >
        <div style={{ paddingTop: "10px" }} className="ng-binding">
          ${cart.Qty * cart.Price}
        </div>
        <div
          style={{ paddingTop: "10px", paddingBottom: "5px", textAlign: "center", }}
        >
     
           
            <img src={remove} type="button" alt="" onClick={ () => {
              const store = Store.getState();
              deleteButtonClicked({ProductID: cart.ProductID,orderCartId: store.cart.orderCartId})
            }
              } />
      
        </div>
      </td>
    </tr>
  ));


  return (
    <main className="pl-3 pr-3 mb-5">
      <div className="container">
        <div className="d-flex cartBackground cartFont mt-5 mb-2">
          <div className="mr-auto p-1 mt-3">
            <p>SHOPPING CART: Your cart contains {count} items</p>
          </div>
          <div className="p-1 mt-3">
            <NavLink to="/Shop">
              <figure className="">
                <img src={continueShopping} alt="" className="pr-1" />
              </figure>
            </NavLink>
          </div>
{carts.length > 0 ? 
          <div className="p-1 mt-3">
            <NavLink to="/cart/checkout">
              <figure className="">
                <img src={checkout} alt="" />
              </figure>
            </NavLink>
          </div>
: ""}
        </div>
        {carts.length > 0 ? 
        <div
          style={{
            backgroundColor: "#e8e3c8",
            margin: "15px 0 0 0",
            padding: "10px",
          }}
          className="container"
        >
          <table border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td
                  style={{
                    width: "500px",
                    height: "25px",
                    textAlign: "left",
                    padding: "5px",
                    backgroundColor: "#d2be9d",
                    color: "#4b290c",
                    fontWeight: "bold",
                    borderTop: "1px dashed #929292",
                    borderLeft: "1px dashed #929292",
                  }}
                >
                  Shipping to You
                </td>
                <td
                  className="cartTableLabel"
                  style={{ borderLeft: "1px dashed #929292" }}
                >
                  Price
                </td>
                <td
                  className="cartTableLabel"
                  style={{ borderLeft: "1px dashed #929292" }}
                >
                  Quantity
                </td>
                <td
                  className="cartTableLabel"
                  style={{ borderLeft: "1px dashed #929292" }}
                >
                  Total
                </td>
              </tr>

              {renderedCarts}

              <tr>
                <td
                  style={{
                    width: "500px",
                    textAlign: "left",
                    padding: "5px",
                    color: "#4b290c",
                    borderTop: "1px dashed #929292",
                    borderLeft: "1px dashed #929292",
                    fontWeight: "bold",
                  }}
                >
                  {isLoading && (
                    <span>
                      &nbsp;
                      <img alt="Loader" className="img-fluid" src={loader} />
                      Updating Cart... Please wait
                    </span>
                  )}
                </td>
                <td
                  style={{
                    width: "100px",
                    paddingTop: "5px",
                    height: "35px",
                    color: "#4b290c",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderTop: "1px dashed #929292",
                  }}
                >
                  &nbsp;
                </td>
                <td
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "#4b290c",
                    fontWeight: "bold",
                    textAlign: "right",
                    borderTop: "1px dashed #929292",
                  }}
                >
                  Subtotal:{" "}
                </td>

                <td
                  style={{
                    width: "100px",
                    height: "35px",
                    color: "#4b290c",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderTop: "1px dashed #929292",
                    borderRight: "1px dashed #929292",
                  }}
                  className=""
                >
                  $ {subtotal}
                </td>
              </tr>
              {/* ))} */}
              <tr>
                <td
                  colSpan="4"
                  style={{
                    height: "45px",
                    border: "1px dashed #929292",
                    paddingRight: "5px",
                    textAlign: "right",
                  }}
                >
                  <div className="d-flex row justify-content-end lightCartBackground ml-5">
                    <div className="p-1">
                      <NavLink to="/Shop">
                        <figure className="">
                          <img src={continueShopping} alt="" className="pr-1" />
                        </figure>
                      </NavLink>
                    </div>
                    <div className="p-1">
                      <NavLink to="/cart/checkout">
                        <figure className="">
                          <img src={checkout} alt="" />
                        </figure>
                      </NavLink>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
: ""}

      </div>
    </main>
  );
}

export default CartPage;