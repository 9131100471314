import  React from "react";
import {useSelector} from 'react-redux';

import BPHeader from "../shared/images/shop/BakingPlanks_Header.png";
import CategorySideMenu from "./CategorySideMenu";
import PaperBottom from "./PaperBottom";
import ShopSection from "./ShopSection";



const BakingPlanks = () => {
  const products = useSelector(state => state.products);
  // const categories = useSelector(state => state.categories);

  

  return (
<>
    <CategorySideMenu categoryImagePath={BPHeader}/> 
    {products.map((product) => ( 
      <>
      {product.categoryId === 3 ? 
       <>
      <ShopSection
        
        productId={product.productId}
        productName={product.name}
        productDescription={product.description}
        priceDescription={product.priceDescription}
        productImagePath={product.imagePath}
        price={product.price}
      />
  </>
   : ""}
  </>
  ))}
  <div className="mr-2">
  <PaperBottom />
  </div>
  </>
  )
}
export default BakingPlanks;
