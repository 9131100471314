import React from 'react';
import { NavLink } from 'react-router-dom';

import spiceRubs from "../../components/shared/images/shop/SpiceRubs_cube.png";
import cookbooks from "../../components/shared/images/shop/Cookbooks_cube.png";
import bakingPlanks from "../../components/shared/images/shop/BakingPlanks_cube.png";
import bbqPlanks from "../../components/shared/images/shop/BBQ_Planks_cube.png";
import shopHeader from '../../components/shared/images/shop/Shop_MenuHeader.png';

import paperBottom from '../../components/shared/images/about/PaperBackground_Bottom.png';


const ShopPage = () => {
    return (
<main className="pl-3 pr-3">
    
    <div className="container">
      {/* // small shop dropdown */}
        <div className="row ">
          <div className="dropdown col-sm-12 d-lg-none text-center center">
            <button
              className="btn dropdown-toggle banner  mt-3 mb-4 text-white"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{width:"280px"}}
            >
              <h2 style={{color:"#DBCB98"}} >Shop</h2>
    
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            >
              <NavLink 
                className="nav-link-bottom dropdown-item"
                to="/Shop/SpiceRubs"
              >
                SPICE RUBS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom dropdown-item"
                to="/Shop/Cookbooks"
              >
                COOKBOOKS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom dropdown-item"
                to="/Shop/Bakingplanks"
              >
                BAKING PLANKS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom dropdown-item"
                to="/Shop/bbqgplanks"
              >
                BBQ PLANKS
              </NavLink> 
              <NavLink 
                className="nav-link-bottom dropdown-item"
                to="/Shop/bbqgplanks"
              >
                NutDriver
              </NavLink> 
            </div>
          </div>
          </div>
          {/* <!-- // Side Menu --> */}
    
    <div className="row">
          <section
            className="col-4 d-none d-lg-block center mt-5"
            style={{height: "100px", fontSize: "14px"}}
          >
            <ul className="hideUlDot " style={{width: "250px"}}>
                <figure>
                    <img src={shopHeader} alt="" />
                </figure>
    
                <div style={{textAlign:"center", maxWidth:"225px", margin: "0 auto"}}>
    
                  <div style={{ margin:"10px 0px 10px 10px" }} className="dotted_line"></div>
    
                      <div>
                          <NavLink  to="/Shop/SpiceRubs">
                              <span className="leftMenuTitle">SPICE RUBS</span>
                          </NavLink> 
                      </div>
                      <div style={{margin:"10px 0px 10px 10px"}} className="dotted_line"></div>
                      <div>
                          <NavLink  to="/Shop/Cookbooks">
                              <span className="leftMenuTitle">COOKBOOKS</span>
                          </NavLink> 
                      </div>
                      <div style={{margin:"10px 0px 10px 10px"}} className="dotted_line"></div>
                      <div>
                          <NavLink  to="/Shop/Bakingplanks">
                              <span className="leftMenuTitle">BAKING PLANKS</span>
                          </NavLink> 
                      </div>
                      <div style={{margin:"10px 0px 10px 10px"}} className="dotted_line"></div>
                      <div>
                          <NavLink  to="/Shop/BBQplanks">
                              <span className="leftMenuTitle">BBQ PLANKS</span>
                          </NavLink> 
                      </div>
                      <div style={{margin:"10px 0px 10px 10px"}} className="dotted_line"></div>
                      <div>
                          <NavLink  to="/Shop/Nutdriver">
                              <span className="leftMenuTitle">NUT DRIVER</span>
                          </NavLink> 
                      </div>
                      <div style={{margin:"10px 0px 10px 10px"}} className="dotted_line"></div>
              </div>
              </ul>
              </section>
    
                {/* <!-- // Medium to Large Screen --> */}
    
          <section className=" d-none d-md-block col-md-12 col-lg-8 mt-3 ">
            <div className=" row mt-5 justify-content-center">
              <div className="paperBackground pr-4 pl-3">
                <div className="boarderTop  mt-4 pr-2 pl-2" style={{width:"600px"}}></div>
                  <div style={{textAlign:"left"}}>
                  <h2
                    className="col-6 dotted_line_right boarderBottom pr-3"
                  >
                    PLANK COOKING PRODUCTS
                  </h2>
                </div>
                
                <div className="" style={{width: "620px", fontSize: "14px"}}>
                  <div className="text-center">
                    <NavLink  to="/Shop/SpiceRubs">           
                     <img src={spiceRubs} alt="" />
                    </NavLink> 
                    <NavLink  to="/Shop/Cookbooks">              
                        <img src={cookbooks} alt="" />
                    </NavLink> 
                    <NavLink  to="/Shop/Bakingplanks">              
                        <img src={bakingPlanks} alt="" />
                    </NavLink> 
                    <NavLink  to="/Shop/BBQplanks">             
                        <img src={bbqPlanks} alt="" />
                    </NavLink> 
                  </div>
                </div>
              </div>
              
              {/* <!-- // Paper Bottom --> */}
              <div className="d-none d-lg-block ">
                <div className=" row">
                    <figure className="pr-3">                   
                        <img src={paperBottom} alt="" />      
                    </figure>
                </div>
              </div>
              <div className="d-none d-md-block d-lg-none ">
                <div className=" row">
                    <figure className="pr-2">
                        
                        <img src={paperBottom} alt="" className="" />
                    </figure>
                </div>
              </div>
            </div>
          </section>
        </div>
              
    
          {/* <!-- //Medium to Small Screen --> */}
    
          <div className="d-md-none justify-content-center">
          <section className="row justify-content-center">
              <div className="  boarderTop " style={{width:"430px"}}>
                <div className=" dotted_line_right" style={{width:"230px"}}>
                  <h2 className="  boarderBottom  " style={{textAlign:"left", color:"#9c7245"}} >
                    PLANK COOKING PRODUCTS
                  </h2>
                </div>
              </div>
              
              <div className=" d-md-none" style={{width:"620px"}} >
                <div className="col-12 text-center">
                  <NavLink  to="/Shop/SpiceRubs">
                    <img src={spiceRubs} alt="" />
                  </NavLink> 
                  <NavLink  to="/Shop/Cookbooks">
                    
                    <img src={cookbooks} alt="" />
                  </NavLink> 
                  <NavLink  to="/Shop/Bakingplanks">
                    
                    <img src={bakingPlanks} alt="" />
                  </NavLink> 
                  <NavLink  to="/Shop/BBQplanks">
                    
                    <img src={bbqPlanks} alt="" />
                  </NavLink> 
                </div>
              </div>
          </section>
          </div>
        </div>
        
      </main>
    );
}

export default ShopPage;