import React, { useState, useEffect } from "react";

import { Store } from "./../../redux/Store";

const ShippingToYou = () => {
  const [carts, setCarts] = useState([]);
  const [subtotal, setSubTotal] = useState(0);
  const [isLoading, ] = useState(false);

  useEffect(() => {
    const store = Store.getState();
    setCarts(store.cart.cartList);
    setSubTotal(
      store.cart.cartList.reduce(function (cnt, o) {
        return cnt + o.Price * o.Qty;
      }, 0)
    );
  }, []);

  let productTotal = 0;

  if (carts.length > 0) {
    productTotal = carts.Qty * carts.Price;
  }

  const renderedCarts = carts.map((cart) => (
    <tr className="scope">
      <td
        style={{
          width: "500px",
          textAlign: "left",
          padding: "5px",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className=""
      >
        {cart.Name}
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
        className="ng-binding"
      >
        ${cart.Price}
      </td>
      <td
        style={{
          width: "100px",
          padding: "5px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
        }}
      >
        <p>{cart.Qty}</p>
      </td>
      <td
        style={{
          width: "100px",
          textAlign: "center",
          color: "#4b290c",
          borderTop: "1px dashed #929292",
          borderLeft: "1px dashed #929292",
          borderRight: "1px dashed #929292",
        }}
      >
        <div style={{ paddingTop: "10px" }} className="ng-binding">
          ${cart.Qty * cart.Price}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "5px",
            textAlign: "center",
          }}
        ></div>
      </td>
    </tr>
  ));
  return (
    /* <!-- Checkout box --> */

    <div
      style={{
        backgroundColor: "#e8e3c8",
        margin: "15px 0 0 0",
        padding: "0px",
      }}
      className="container"
    >
      <table border="0" cellPadding="0" cellSpacing="0" width="100%">
        <tbody>
          <tr>
            <td
              style={{
                width: "500px",
                height: "25px",
                textAlign: "left",
                padding: "0px",
                backgroundColor: "#d2be9d",
                color: "#4b290c",
                fontWeight: "bold",
                borderTop: "1px dashed #929292",
                borderLeft: "1px dashed #929292",
              }}
            >
              Shipping to You
            </td>
            <td
              className="cartTableLabel"
              style={{ borderLeft: "1px dashed #929292" }}
            >
              Price
            </td>
            <td
              className="cartTableLabel"
              style={{ borderLeft: "1px dashed #929292" }}
            >
              Quantity
            </td>
            <td
              className="cartTableLabel"
              style={{
                borderLeft: "1px dashed #929292",
                borderRight: "1px dashed #929292",
              }}
            >
              Total
            </td>
          </tr>

          {renderedCarts}

          <tr>
            <td
              style={{
                width: "500px",
                textAlign: "left",
                padding: "5px",
                color: "#4b290c",
                borderTop: "1px dashed #929292",
                borderLeft: "1px dashed #929292",
                borderBottom: "1px dashed #929292",
                fontWeight: "bold",
              }}
            >
              {isLoading && (
                <span>
                  &nbsp;
                  {/* <img alt="Loader"
 className="img-fluid"
  src={loader} /> */}
                  Updating Cart... Please wait
                </span>
              )}
            </td>
            <td
              style={{
                width: "100px",
                paddingTop: "5px",
                height: "35px",
                color: "#4b290c",
                fontWeight: "bold",
                textAlign: "center",
                borderTop: "1px dashed #929292",
                borderBottom: "1px dashed #929292",
              }}
            >
              &nbsp;
            </td>
            <td
              style={{
                width: "100px",
                height: "35px",
                color: "#4b290c",
                fontWeight: "bold",
                textAlign: "right",
                borderTop: "1px dashed #929292",
                borderBottom: "1px dashed #929292",
              }}
            >
              Subtotal:{" "}
            </td>

            <td
              style={{
                width: "100px",
                height: "35px",
                color: "#4b290c",
                fontWeight: "bold",
                textAlign: "center",
                borderTop: "1px dashed #929292",
                borderRight: "1px dashed #929292",
                borderBottom: "1px dashed #929292",
              }}
              className=""
            >
              ${subtotal}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ShippingToYou;
