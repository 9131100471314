import React from 'react';

import contact from '../shared/images/contact/Contact_MenuHeader.png';
import paperBottom from "../shared/images/PaperBackground_Bottom.png";


const ContactPage = () => {
    return (
        <main class="pl-1 pr-1">
  <div class="container">
    <div class="">

        <div class="btn-group mt-3  d-lg-none">
            <button class="btn btn-custom btn-lg " type="button" aria-haspopup="true" aria-expanded="false" style={{width:"280px", backgroundColor:"#22190f", color:"#f8f3dc"}}>
                <span style={{fontSize:"24px"}}>Contact Us</span>
            </button>
        </div>

      <section class="col-sm-12 d-md-none mb-5">
        <div class=" row">
        <div className="col-12  boarderTop mt-3" ></div>
            <div className="content_title col-6 dotted_line_right dotted_line_bottom " style={{ textAlign: "left"}} >
                Contact Us
            </div>
        
          {/* <div class="content_title boarderTop ">
            <div class="dotted_line_bottom dotted_line_right content_title col-12">
              CONTACT US
            </div>
          </div> */}
          <div class="" style={{width: "610px", fontSize: "14px", textAlign: "left", fontWeight:"500"}}>
            <p>
              You can contact us one of two ways; click one
              of the email links below to send us an email, or call the
              customer service number.
            </p>
            <p>CUSTOMER SERVICE:</p>
            <p style={{color: "#9C805F"}}>ChefHowie@plankcooking.com </p>
            <p>Phone: 425.881.0422 </p>
            <p>Fax: 425.881.7338 </p>
            <p class="dotted_line_bottom"></p>
            <p>
              WHOLESALE OPPORTUNITIES are available, please contact
              customer service for more information.
            </p>
          </div>
        </div>
        </section>
    </div>
   


    

<div class="row">
    <div class="col-lg-3 d-none d-lg-block mt-5 ">
      <figure class="">
        <img src={contact} class="ml-2" alt=""/>
      </figure>
    </div>


    {/* <!-- <section class="d-none d-md-block col-8 mt-5 mb-5">
      <div class="row ">
        <div id="" class="boardBackgroundShop">
            <figure>
                <img src="/images/shop/SpiceRubs_Header.png" class="mainPic" style="position: relative;"/>
            </figure>        
        </div>
        </div>
        </section> --> */}

    <section class="d-none d-md-block col-md-12 col-lg-8 ">
      <div class="row justify-content-center">
        <div class=" paperBackground mt-5 ">
          <div class="dotted_line_bottom mt-4"></div>
          <div class="content_title  ">
            <div class="dotted_line_bottom dotted_line_right content_title col-6" style={{textAlign: "left"}}>
              CONTACT US
            </div>
          </div>
          <div class=" pl-3 pr-3" style={{width: "610px", fontSize: "14px", textAlign: "left", fontWeight:"500"}}>
            <p className="pt-1">

              You can contact us one of two ways; click one
              of the email links below to send us an email, or call the
              customer service number.
            </p>
            <p className="pt-3">CUSTOMER SERVICE:</p>
            <p className="pt-1" style={{color: "#9C805F"}}>ChefHowie@plankcooking.com </p>
            <p className="pt-1">Phone: 425.881.0422 </p>
            <p className="pt-1">Fax: 425.881.7338 </p>
            <p class="dotted_line_bottom pb-1"></p>
            <p className="pt-3">
              WHOLESALE OPPORTUNITIES are available, please contact
              customer service for more information.
            </p>
          </div>
        </div>
        <div class=" row d-none d-md-block mb-3">
          <figure class="ml-2 ">
            <img src={paperBottom} style={{width: "625px", marginLeft:"10px"}} alt="" />
          </figure>
        </div>
        
      </div>
    </section>
  </div>
  </div>
</main>
    );
}

export default ContactPage;